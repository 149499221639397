.vh__radio input[type="radio"] + label {
  align-items: center;
  border-radius: 10px 10px 0px 0px;
  border-bottom: 1px solid #eaecf0;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  gap: 8px;
  height: 44px;
  justify-content: center;
  line-height: 20px;
  padding: 12px;
}
.vh__radio input[type="radio"]:checked + label {
  align-items: center;
  background: #ecfdf3;
  border-bottom: 2px solid #099774;
  border-radius: 10px 10px 0px 0px;
  color: #099774;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  gap: 8px;
  height: 44px;
  justify-content: center;
  line-height: 20px;
  padding: 12px;
}

.legend__title {
  align-items: center;
  color: rgba(0, 0, 0, 0.58);
  display: flex;
  font-size: 14.3258px;
  font-weight: 400;
  gap: 3px;
  line-height: 21px;
  span {
    width: 10px;
    height: 10px;
    border-radius: 3px;
  }
}

.map-button {
  box-shadow: 0px 3.58146px 19.1011px rgba(26, 186, 147, 0.14);
}

.innerCell {
  cursor: default !important;
}

.visitTyppy {
  border-radius: 8px;
  min-width: 82px;
  min-height: 65px;
  padding: 16px 16px;
  span.title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #1F2A37;
  }

  span.sub__title {
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #1F2A37;
  }
}
