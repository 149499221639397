.visit_detail_popup {
    font-family: 'Inter';
    font-style: normal;
    color: #0BB68C;

    margin: 0 auto;
    top: calc(calc(100% - 403px) / 2);
    transition: top 0.08s;
    max-height: 403px;
    transition: max-height 0.15s;
    transition: top 0.08s;
    border-radius: 12px;

    .outer-radius {
        // box-sizing: border-box;
        background: #FFFFFF;
        border: 1px solid #79C9BC;
        width: 32px;
        height: 32px;
        border-radius: 16px;
        padding: 3px;

        .inter-radius {
            background: #0BB68C;
            width: 24px;
            height: 24px;
            border-radius: 13px;
        }
    }

    .line-expand {
        margin-top: 15px;
        background: #0BB68C;
        height: 1px;
    }
}

.visit-detail-title {
/*height: 24px;*/

/* Text Extra Large/Semibold */

font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 24px;
text-transform: capitalize;

/* Gray modern/800 */

color: #202939;

}
.visit-detail-range-date {
    height: 19px;

/* Text Base/Medium */

font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;

/* Gray/800 */

color: #1D2939;
}

.visit-detail-citystate {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #484F5A;
    height: 19px;
}

.visit-modal-size {
    width: 473px;
    // height: 475px;
    // max-height: 475px;
    overflow-y: hidden;

    &.expanded {
        // height: 654px;
        max-height: 654px;
        top: calc(calc(100% - 654px) / 2);
        transition: max-height 0.15s;
        transition: top 0.08s;
    }
}

.visit-butoon-operation {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px;
    gap: 8px;

    width: 56px;
    height: 56px;

    background: #FFFFFF;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    border-radius: 100px;
}
.visit-button-icon {
    width: 24px;
height: 24px;

font-family: 'Font Awesome 6 Pro';
font-style: normal;
font-weight: 900;
font-size: 24px;
line-height: 24px;
/* identical to box height */

display: flex;
align-items: center;
text-align: center;

/* Brand 2.0/600 */

color: #09A27D;
}
.visit-butoon-text {
/* Text Extra Small/Medium */

font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 15px;
/* identical to box height */

text-align: center;
letter-spacing: 0.01em;

/* Gray neutral/700 */

color: #384250;

    &.deleting {
        color: #0BB68C;
        font-weight: 700;
    }
}

.visit-detail-header {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 8px;
}

.visit-detail-row-title {
/* Text Extra Small/Semibold */

font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 15px;
/* identical to box height */

letter-spacing: 0.03em;

/* Gray neutral/900 */

color: #111927;

    &.soft-text {
        margin-left: 8px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        /* identical to box height */
        
        
        /* Gray neutral/700 */
        
        color: #384250;
    }
}

.shake-animation-small {
    animation: shake 0.30s cubic-bezier(.36, .07, .19, .97) both;
    animation-iteration-count: infinite;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
}

@keyframes shake {

    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}


.visit-define-modal {
    position: relative;
    margin: 0 auto;
    // top: calc(calc(100vh - 309px) / 2);
    border-radius: 12px;
  
    .define-visit-title {
      //styleName: Text Extra Large/Semibold;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      
      display: flex;
      align-items: center;
      text-align: center;
      color: #000000;
    }
    .btn_visit_undo{
        /* Text Small/Regular */
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    
    display: flex;
    align-items: center;
    text-align: right;
    
    color: #000000;
      }
      .btn_visit_done{
/* Text Small/Bold */

font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 17px;
/* identical to box height */

display: flex;
align-items: center;
text-align: right;

/* Success/600 */

color: #039855;
      }
  
    .definte-visit-subtitle {

        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        align-items: center;
        text-align: center;
        color: #000000;
    }
  
    p {
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0em;
      color: #4d5761;
  
      span {
        font-family: Inter;
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 0em;
      }
    }

  }
  

  .define-visit-patient-name{
      /* Text Extra Small/Semibold */

font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 15px;
/* identical to box height */

letter-spacing: 0.03em;

/* Gray neutral/800 */

color: #1F2A37;
  }
  .define-visit-patient-distance{
      margin-top: 4px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    
    display: flex;
    align-items: center;
    
    /* Gray neutral/400 */
    
    color: #9DA4AE;
color: #1F2A37;
}