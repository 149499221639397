.toolbar__container {
  display: flex;
  background-color: #f9f9f9;
  padding: 32px 40px 21px;
}

.toolbar {
  display: flex;
  width: 50%;
}

.tool__btn {
  display: flex;
  text-transform: capitalize !important;
  align-items: center;
  place-content: space-around;
  padding: 0 25px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.search__container {
  display: flex;
  max-width: 55%;
  margin: 0 8px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #d2d6db !important;
  //box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 12px;
  width: 40%;
  input {
    width: 95%;
    border-style: none;
  }
}

.right__section {
  width: 100%;
  display: flex;
  place-content: flex-end;

  .button-add-new-pu {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 8px;

    width: 128px;
    height: 40px;

    /* Brand 2.0/500 */

    background: #0bb68c;
    border-radius: 6px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    text-align: center;

    /* Base/White */

    color: #ffffff;

    &:hover {
      background: #3fd4af;
      color: #ffffff;
    }
    &:active {
      background: #21856c;
      color: #ffffff;
    }
  }

  .button-less {
    width: 16px;
    height: 16px;
    background: none !important;
    padding: 0px 0px;
    svg {
      width: 16px;
      height: 16px;
    }
  }
}
