.list__box_dropdown_patient {
  position: relative;
  border: none;
  padding-bottom: 3rem;
  & button {
    &:hover {
      -webkit-filter: none !important;
      filter: none !important;
    }
    &.success {
      border-color: #0bb68c !important;
    }
    &.error {
      border-color: #ce195a !important;
    }

    /* Gray neutral/25 */
    border-radius: 8px;
    border: 1px solid #d2d6db;
    height: 48px;
    background: #fcfcfd;
    /* Gray neutral/300 */

    /* Extra Small */

    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    & .patient-container-dropdown {
      display: flex;
      align-items: center;
      justify-content: space-between;
      overflow: hidden;
      width: 90%;
      height: 30px;
      &.icon span.patient-value,
      &.icon span.patient-placeholder {
        margin-left: 40px;
      }
    }

    & span.patient-value {
      display: flex;
      align-content: flex-start;
      // width: 100%;
      margin-left: 10px;
      height: 25px;

      /* Text Small/Regular */

      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height */

      /* Gray neutral/900 */

      color: #111927;
    }
    & span.patient-placeholder {
      margin-left: 20px;
      height: 17px;

      /* Text Small/Regular */

      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height */

      /* Gray neutral/500 */

      color: #6c737f;
    }
  }

  & label {
    z-index: 999;

    background-color: white;
    padding: 0 0 0 0.5px;
    position: absolute;
    top: -8px;
    left: 20px;
    height: 15px;

    /* Text Extra Small/Medium */

    font-family: "Inter";
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    letter-spacing: 0.01em;

    /* Gray neutral/700 */

    color: #384250;

    span.span-required {
      color: #d92d20 !important;
    }
  }
}
.dropdown__pointer_patient {
  display: flex;
  position: absolute;
  align-items: center;
  top: 0;
  bottom: 0;
  right: 0;
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 12px;

  height: 48px;
  & .dropdown-pointer {
    width: 11px;
    height: 12px;

    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;
    padding-right: 1.5rem;

    /* Gray neutral/700 */

    color: #384250;
  }
}

.dropdown-patient-options {
  &.working {
    top: -210px;
  }
  z-index: 1111;

  width: 100%;
  border: 1px solid #d2d6db;
  position: absolute;
  height: 150px;
  overflow: auto;
  padding: 2px;

  /* Base/White */

  background: #ffffff;
  /* Medium */

  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  border-radius: 8px;

  &::-webkit-scrollbar {
    width: 8px;

    /* Gray neutral/200 */
  }
  &::-webkit-scrollbar-track {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    position: relative;
    margin-right: 2px !important;

    height: 90px !important;

    /* Gray neutral/200 */

    border-radius: 8px !important;
    background: #e5e7eb !important;
  }
}
.dropdown-li-options-multiple {
  &.not-record {
    display: flex;
    justify-content: center;
    pointer-events: none;
  }
  padding: 10px 16px !important;
  gap: 8px;
  height: 39px;
  background: #ffffff;

  //   background: #f9fafb;
  &:hover{
    background: #e5e7eb;
   }
  & span {
    height: 17px;

    /* Text Small/Regular */

    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    /* Gray neutral/900 */

    color: #111927;

    &.dropdown-check-icon {
      position: absolute;
      right: 5px;
      & svg {
        width: 20px;
        height: 19px;

        font-family: "Font Awesome 6 Pro";
        font-style: normal;
        font-weight: 900;
        font-size: 20px;
        line-height: 20px;

        /* Brand 2.0/500 */

        color: #0bb68c;
      }
    }
  }
}


.badge-patient-value{
  background-color: #70dabf;
  padding: 2px 8px 2px 8px;
  border-radius: 8px;
  white-space: nowrap;
  overflow: hidden;
}