* {
  margin: 0;
  padding: 0;
}

body {
  color: #7c7c8e;
  font-family: $font_primary, "Montserrat", "Lato", sans-serif;
  background-color: #f9f9f9;
}
.modal__freq{
  overflow: hidden !important;
}
:focus {
  outline: none;
  box-shadow: inherit !important;
}

.link {
  transition: 0.1s;

  &:hover {
    text-decoration-line: underline;
  }
}

.c-wrapper {
  margin-left: 256px;
}

.container {
  padding: 24px 40px;
}

h1 {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */

  /* Gray */

  color: $font-gray;
}

h4 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $dark-gray;
}

h5 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $dark-gray;
}

h6 {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

button {
  border-style: none;

  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  transition: all 0.2s;
}

// Their

.logo img {
  width: 120px;
}

.dropdown button:hover {
  background: none;
}

.c-subheader-nav {
  padding-left: 2rem !important;
}

#admission-bar-chart {
  max-height: 550px;
  //   position: relative;
}

.c-wrapper:not(.c-wrapper-fluid) .c-header-fixed {
  z-index: 99999;
  max-height: 100vh;
}

.is-mini {
  padding: 13px 15px;

  a {
    padding: 0 !important;
  }
}

.dropdown button {
  background: none;
  border: none;
}

.btn-primary.active,
.btn-primary:active,
.open>.dropdown-toggle.btn-primary {
  background: none;
  border: none;
}

.min-align {
  text-align: center;

  i {
    margin: 0 !important;
  }
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open>.dropdown-toggle.btn-primary.focus,
.open>.dropdown-toggle.btn-primary:focus,
.open>.dropdown-toggle.btn-primary:hover {
  background: none;
  border: none;
}

.dropdown-menu>li>a {
  font-size: 14px;
  line-height: 0;
  padding: 10px;

  i {
    margin-right: 5px;
  }
}

.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: inherit;
}

li.c-sidebar-nav-item a {
  padding: 15px 20px;
}

.c-main {
  padding-top: 3rem !important;
}

.fade.show {
  opacity: 9 !important;
}

.logo {
  width: 100%;
  padding: 0 10px;
  margin-bottom: 0;
}

.logo img {
  width: 120px;
  margin: 0 10px 0 0px;
}

.logo label {
  font-size: 16px;
  position: relative;
  top: 3px;
  font-weight: 400;
}

.breadcrumb>li+li:before {
  padding: 1px 5px !important;
}

.breadcrumb-item.active {
  color: rgb(68, 68, 68);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  color: #8a93a2;
}

li.breadcrumb-item a {
  color: rgb(31, 36, 41);

  font-size: 16px;
  font-weight: 500;
}

.fa-pen {
  color: #0bb68c !important;
  border-color: #0bb68c;
  cursor: pointer;
}

.c-sidebar-minimized .logo span {
  width: 100%;
  text-align: center;
}

.center-flex {
  display: flex;
  justify-content: center;
  align-content: center;
}

.logo label strong {
  font-weight: 600;
}

.logo p {
  float: left;
  width: 80%;
  margin: 0;
}

.logo span {
  font-size: 18px;
  float: right;
  padding: 15px 5px;
}

.hand {
  cursor: pointer;
}

.underline-text {
  text-decoration: underline;
}

.sample-csv {
  font-size: 12px;
}

.payment {
  padding: 12px 16px 26px 12px !important;
}

input[type="date"] {
  text-transform: uppercase;
}

.manual-schedule {
  display: flex;
  justify-content: center;
}

.time-selection {
  display: flex;

  .form-control {
    margin-right: 2px;
  }
}

.black-text {
  color: #000 !important;
}

.green-text {
  color: #0bb68c;
  font-size: 20px;
}

.transparent-text {
  color: transparent;
}

.check-mark-text {
  color: gainsboro;
  font-size: 20px;
}

.header {
  width: 100%;
  padding: 5px 20px;
  border-bottom: 3px solid rgb(255, 255, 255);

  img {
    width: 50px;
    border-radius: 50%;
  }

  .header-admin {
    span {
      background: #3db68c;
      color: #fff;
      font-size: 12px;
      font-weight: 600;
      padding: 1px 12px 2px 12px;
      border-radius: 3px;
      // float: left;
      // margin-left: 10px;
    }

    small.agency-name {
      display: block;
    }
  }

  .admin-img {
    float: right;
  }

  .toggle-menu i {
    color: rgb(96, 96, 96);
    font-size: 18px;
    margin: 19px 0 0 10px;
  }

  .toggle-menu {
    float: right;
  }

  .header-inner {
    display: inline-block;
    margin: 0;

    #menu1 {
      width: 100%;
      text-align: right;
    }
  }

  .header-admin {
    width: 100%;
  }

  .header-admin label {
    color: rgb(68, 68, 68);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    width: 100%;
    margin: 0;
    display: block;
    // padding-left: 10px;
  }
}

.green-btn {
  width: 100%;
  height: 55px;
  border-radius: 6px;
  color: white;
  font-size: 18px;
  background: #3db68c;
  font-weight: 500;
  border: 1px solid #3db68c;
}

.login {
  padding-top: 50px;
  background: #fff;

  img {
    width: 90%;
    position: relative;
    top: 50px;
  }

  .text-danger {
    font-size: 11px;
  }

  .err.text-danger {
    position: relative;
    top: -20px;
    font-size: 12px;
  }

  .logo h2 {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .col-md-5 {
    background: #ffffff;
    box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.06);
    border-radius: 30px;
    padding: 25px 40px;
  }

  .logo h4 {
    color: #b5b5b5;
    font-size: 14px;
  }

  .forgot_pw {
    margin-top: -7px;
  }

  .logo {
    margin-bottom: 30px;
    box-shadow: inherit;
    box-shadow: none;
  }

  .logo img {
    width: 250px;
    position: inherit;
    margin-bottom: 30px;
  }

  .form-check input {
    height: auto;
    float: left;
    margin: 0 10px 0 0;
    width: auto;
  }

  .form-check label {
    font-size: 14px;
    color: rgb(120, 120, 120);
    font-weight: 400;
    position: relative;
    top: -4px;
    font-family: "Inter", sans-serif;
  }

  h3 {
    color: rgb(35, 35, 35);
    font-size: 29px;
    font-weight: 600;
    margin-bottom: 35px;
  }

  input {
    width: 100%;
    margin-bottom: 25px;
    color: #787878;
    font-size: 14px;
    background: #ffffff;
    border: 1px solid #dfdfdf;
    box-sizing: border-box;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
    font-weight: 400;
    height: 45px;
    padding-bottom: 5px;
    border-radius: 0;
  }

  ::-webkit-input-placeholder {
    color: rgb(120, 120, 120);
    font-size: 14px;
    font-weight: 400;
  }

  ::-moz-placeholder {
    color: rgb(120, 120, 120);
    font-size: 14px;
    font-weight: 400;
  }

  :-ms-input-placeholder {
    color: rgb(120, 120, 120);
    font-size: 14px;
    font-weight: 400;
  }

  :-moz-placeholder {
    color: rgb(120, 120, 120);
    font-size: 14px;
    font-weight: 400;
  }

  .form-check {
    padding: 0 !important;
  }

  label.hand.forgot_pw a {
    color: #787878;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    position: relative;
    text-decoration: none;
    cursor: pointer;
    top: 5px;
  }

  .submit_row {
    margin-top: 45px;
    margin-bottom: 50px;
  }

  button.green-btn i {
    margin: 0 5px 0 0px;
  }

  button.green-btn {
    width: 100%;
    height: 55px;
    border-radius: 6px;
    color: white;
    font-size: 18px;
    background: #3db68c;
    font-weight: 500;
    border: 1px solid #3db68c;
  }

  button.blue-btn:disabled {
    background: #0000f1;
    border: 1px solid #0000f1;
    cursor: not-allowed;
  }

  &.forgot-password {
    .logo {
      padding: 0;
    }

    .col-md-5 {
      margin-top: 50px;
    }
  }

  .alternative-opt {
    display: flex;
    justify-content: center;
    padding: 15px;
    font-size: 16px;
    text-decoration: underline;
  }
}

.saveFrequency {
  color: white;
  font-size: 14px;
  background: #3db68c;
  font-weight: 500;
  border-radius: 6px;
  margin-left: 4px;
  padding: 2px;
  border: 1px solid #3db68c;

  :active {
    border-color: #3db68c;
  }
}

.white-container {
  textarea {
    font-size: 14px;
    padding: 40px;
  }

  background: #fff;
  padding: 15px 25px 50px 25px;
  position: relative;

  .users-number {
    position: relative;
    top: -5px;
  }

  .users-number span {
    display: inline-block;
    width: 100%;
    text-align: center;
    font-size: 16px;
  }

  .users-number label {
    font-weight: 600;
    font-size: 24px;
    margin: 0;
    text-align: center;
    margin-bottom: -5px;
    border: 0;
  }

  small {
    font-size: 11px;
    position: absolute;
    right: 0;
  }

  h2 {
    color: rgb(35, 35, 35);
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 35px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
  }

  select {
    width: 100%;
    height: 40px;
    font-weight: 400;
    font-size: 14px;
    padding: 0 3px;
    color: #7a7a7a;
  }

  form p,
  form .p-container {
    float: left;
    width: 45%;
    margin: 0 45px 30px 0px;
    position: relative;
  }

  input {
    width: 100%;
    height: 40px;
    font-size: 14px;
    padding: 0 15px;
  }

  button {
    width: 125px;
    border-radius: 5px;
    margin: 0 20px 0 0px;
    height: 40px;
    font-size: 18px;
    font-weight: 400;
    color: #fff;
    border: none;
  }

  .save {
    background: #3db68c;
  }

  .button-mysave {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 8px;
    width: 126px;
    height: 35px;
    background: #0bb68c;
    border-radius: 6px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;

    &:hover {
      background: #3fd4af;
      color: #ffffff;
    }

    &:active {
      background: #21856c;
      color: #ffffff;
    }
  }

  .save:disabled {
    background: #84b68a;
    cursor: not-allowed;
  }

  .cancel {
    background: #ec6b66;
    margin: 0;
  }

  .cancel:disabled {
    cursor: not-allowed;
  }

  .discharge {
    background: #edd300;
    margin: 0;
    margin-left: 20px;

    &.to-activate {
      background: #3db68c;
    }
  }

  .discharge:disabled {
    cursor: not-allowed;
  }

  label {
    width: 100%;
    color: #7a7a7a;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 400;
  }

  .row.buttons {
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .user-upload {
    margin-bottom: 45px;
    position: relative;
    width: 200px;
  }

  .user-upload img {
    width: 150px;
  }

  .user-upload span img {
    width: 30px;
    position: absolute;
    bottom: 25px;
    right: 43px;
  }

  .user-upload {
    margin-bottom: 45px;
    position: relative;
  }

  .buttons i {
    color: #a6a6a6;
    font-size: 28px;
    cursor: pointer;
  }

  .user-upload img {
    width: 150px;
  }

  ::-webkit-input-placeholder {
    color: #777;
    font-size: 14px;
    font-weight: inherit;
  }

  ::-moz-placeholder {
    color: #777;
    font-size: 14px;
    font-weight: inherit;
  }

  :-ms-input-placeholder {
    color: #777;
    font-size: 14px;
    font-weight: inherit;
  }

  :-moz-placeholder {
    color: #777;
    font-size: 14px;
    font-weight: inherit;
  }
}

.filter-patients {
  height: 30px !important;
  top: 12px;
  position: relative;
}

.purple_pill {
  background: #a594cc;
}

.green_pill {
  background: #4dbd73;
}

.red_pill {
  background: #ec6b66;
}

.gray_pill {
  background: #5f5f5f;
}

.yellow_pill {
  background: #e3ad62;
}

// BADGES
.badges {
  color: #fff !important;
  opacity: 1 !important;
  width: auto;
  padding: 1px 5px;
  display: inline-block;
  text-align: center;
  border-radius: 3px;
}

.duplicates-view {
  height: 250px;
  overflow: scroll;
}

.table.data {
  .badges {
    width: 80px;
    text-transform: uppercase;
  }

  // TABLES

  thead tr th {
    font-size: 16px;
    color: #000;
    padding-bottom: 10px;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;

    span.contain-head-ico {
      position: relative;
      padding-right: 15px;
    }

    i {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  td {
    font-size: 14px;
    cursor: pointer;
    padding-bottom: 15px;
    // border-bottom: 1px solid #ddd;
    padding-top: 15px;
  }
}

.search-section input {
  border: none;
  width: 90%;
  padding: 0;
}

.search-bar i {
  position: absolute;
  font-size: 14px;
  top: 12px;
  right: 25px;
  color: #e9e9e9;
}

.search-section .search-bar {
  border-bottom: 2px solid #e9e9e9;
  position: relative;
  left: -20px;
}

.search-section {
  margin-bottom: 50px;
  margin-top: 25px;
}

.version {
  left: 0;
  right: 0;
  bottom: 10px;
  text-align: center;
  font-size: 10px;
}

.version i {
  margin: 0px 5px 0px 0;
}

.errorMsg {
  color: red;
}

.error-block {
  position: absolute;
  top: 65px;
  color: red;
}

.err-block {
  position: absolute;
  color: red;
}

.patient-detail {

  .col-md-2,
  .col-md-3,
  .col-md-12,
  .col-md-4 {
    margin-bottom: 1.5rem;
  }

  h1 {
    font-weight: 600;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;

    span {
      float: right;
      padding-top: 7px;

      i {
        margin-left: 5px;
        margin-right: 15px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .personal-detail {
    margin: 15px 0 30px 0;
  }

  h4 {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 30px;
  }

  span {
    font-size: 14px;
    opacity: 0.71;
    color: #444444;
    margin-bottom: 2px;
    display: inline-block;

    &.freq {
      word-break: break-all;
    }
  }

  em {
    opacity: 0.7;
    display: inline-block;
    color: #000;
    font-weight: 600;
    font-size: 14px;
    font-style: normal;
    width: 100%;
  }

  .notes {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .notes h4 {
    margin-bottom: 5px;
  }

  p {
    font-size: 14px;
  }

  .save-btn {
    background: #3db68c;
  }

  .cancel-btn {
    background: #ec6b66;
  }

  .buttons i {
    float: right;
    cursor: pointer;
  }

  button {
    box-shadow: 10px 10px 10px #cccccc54;
    font-size: 14px;
  }
}

.history-calendar {
  padding: 0 15px;
}

.select-dropdown {
  label {
    color: #000;
    font-size: 12px;
    margin-bottom: 5px;
    opacity: 0.7;
  }

  select {
    margin-bottom: 20px;
  }

  .col-md-10 {
    padding: 0;
  }

  .col-md-12 {
    padding: 0;
  }
}

.inplace-confirm {
  position: relative;

  .main-body {
    z-index: 999;
    background: #fff;
    text-align: center;
    padding: 10px 0;
    color: #000;
    font-size: 14px;
    width: 200px;
    // background: red;
    border: 2px solid gainsboro;
    position: absolute;
    right: 7%;
    bottom: -20px;
    box-shadow: 5px 10px 10px gainsboro;
    border-radius: 15px;

    button {
      width: 80px;
      border-radius: 15px;
      height: 30px;
      font-size: 12px;
      font-weight: 400;
      background-color: #949494;
      color: #fff;
      border: none;
      margin: 5px;
    }

    .confirm-text {
      border-bottom: 2px solid gainsboro;
      margin-bottom: 10px;
      padding: 10px;
    }

    #yes-action {
      background: #57d795;
    }

    #no-action {
      background: #de5555;
    }
  }

  .confirm-btn-html {
    padding-top: 12px;

    &.un-mark-miss {
      padding: 0;
      display: inline;
      float: left;
    }
  }
}

.cust-schedule {
  display: inline-flex;
}

.loader {
  height: 70vh;
  display: table;
  width: 100%;
  position: relative;
  top: 30%;

  .loader-inner {
    height: 70vh;
    display: table-cell;
    vertical-align: middle;
  }
}

ul.custom_pagination {
  margin: 10px 15px 25px 0;
  padding: 0;

  li {
    cursor: pointer;
    list-style: none;
    display: inline;
    border: 1px solid #9c9c9c4a;
    padding: 6px 15px;

    &.active {
      background: #3db68c;

      a {
        color: #fff;
      }
    }

    a {
      text-decoration: none;
      color: #9c9c9c;
    }
  }
}

.head-button-input-grp {
  margin-top: 20px;

  label {
    border-bottom: 1px solid #ccc;

    input {
      border: none;
      padding: 1px;
      margin: 0;
      width: 90%;
      position: relative;
      top: 7px;
      background: transparent;
    }

    i {
      top: 7px;
      position: relative;
      margin-left: 10px;
    }
  }
}

.export {
  float: right;
  padding-top: 10px;
  font-size: 17px;
  color: #777 !important;

  i {
    padding-left: 7px;
  }
}

.page-size {
  display: flex;
  justify-content: center;

  div {
    font-size: 14px;
    width: 50%;
    float: left;
    margin-top: 10px;
  }

  select {
    width: 40%;
    float: right;
  }
}

.popup-content {
  padding: 0;
  background-color: #0000005c;
  border: none;
  min-width: 30vw;
  min-height: 55vh;
  width: 100% !important;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  //width: -webkit-fit-content;
  //width: -moz-fit-content;
  //   h4 {
  //     font-size: 18px;
  //     font-weight: 600;
  //     margin-bottom: 15px;
  //     i {
  //       float: right;
  //     }
  //   }
  // &::-webkit-scrollbar {
  //   display: none;
  // }

  // @media only screen and (max-width: 1440px) {
  //   width: 35%;
  // }
  // @media only screen and (max-width: 1200px) {
  //   width: 40%;
  // }
  // @media only screen and (max-width: 770px) {
  //   width: 70%;
  // }
  // @media only screen and (max-width: 440px) {
  //   width: 90%;
  // }
}

.patient-schedules {
  .page-size {
    div {
      font-size: 14px;
      width: 30%;
      float: left;
      margin-top: 10px;
    }

    input {
      width: 70%;
      float: right;
    }
  }
}

.event-options {
  padding: 15px 25px;

  h4 {
    text-align: center;
  }

  .text-center {
    font-size: 14px;
  }

  .options-btn-grp {
    button {
      display: block;
      text-align: center;
      margin: 15px auto;
      width: 80%;
      border-radius: 20px;
      height: 45px;

      &.btn-nav {
        background: #12c5bd;
      }

      &.btn-view {
        background: #57d795;
      }

      &.btn-delete {
        background: #de5555;
      }

      &.btn-mark-as-missed {
        background: gray;
      }

      &.btn-lock {
        background: #12c5bd;
      }
    }
  }
}

.stick-to-bottom {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.date-ctrl {
  display: flex;
  justify-content: center;
  align-items: center;
}

.clinicians {
  th {
    padding: 15px !important;
  }

  .head-btn-grp {
    position: relative;

    span.text-danger {
      position: absolute;
      bottom: 5px;
      left: 15px;
      font-size: 12px;
    }
  }

  .auto-schedule {
    float: right;
    margin-top: 25px;
    background: #fff;
    // padding: 19px 24px;
    height: 70px;
    width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    color: #3db68b;
    margin-right: 20px;
    float: left;

    i {
      font-size: 24px;
    }

    &.is-disabled {
      cursor: not-allowed;
      opacity: 0.7;
    }

    &.is-green {
      background: #3db68b;
      float: right;
    }

    &:hover {
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    }

    i {
      &.fa-plus {
        color: #fff;
      }
    }
  }

  .add-btn {
    margin: 0;
    margin-top: 25px;
    margin-right: 20px;

    button {
      padding: 10px;
      width: 120px;

      &:disabled {
        background: #2e9275de;
        cursor: not-allowed;
      }
    }
  }

  .dot {
    height: 24px;
    width: 24px;
    font-weight: 600;
    padding: 4px;
    font-size: 11px;
    text-align: center;
    display: inline-block;
    border-radius: 9px;
    color: white;
    // z-index: 9999;
    position: relative;
    margin: 0 10px 5px 0px;
  }

  .week-ctrl {

    // text-align: center;
    // margin: 35px 0;
    .contain {
      background: #e5faf4;
      color: #0bb68c;
      padding: 15px;
      font-size: 16px;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      i {
        padding: 0 25px;
      }

      &.disabled {
        background: #cddfda;
      }
    }
  }

  //
  &.caseload-patient-listing {
    th {
      position: sticky;
      top: 0;
      background: #fff;
      z-index: 99;
      padding-left: 5px !important;
    }

    #table-wrapper {
      position: relative;
    }

    #table-scroll {
      height: 645px;
      overflow: auto;

      // margin-top:20px;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    #table-wrapper table {
      width: 100%;
    }

    span.text-danger {
      font-size: 12px;
      margin: 5px 20px;
    }
  }
}

#test {
  height: 82% !important;
}

.patient-schedule-view {
  .head-button-input-grp {
    margin-top: 30px;
  }
}

.due-documents {
  font-size: 14px;

  .col-md-5 {
    margin: 10px 20px;
  }
}

.patient-scheduling {
  font-size: 14px;
}

.patient-schedule {
  height: 100%;
  font-size: 12px;

  .in-progress {
    position: absolute;
    top: 0;
    right: 15px;
  }

  .fc-event-main-frame {
    text-align: center;
    font-size: 12px;
  }

  .active-btn {
    background: #1b5141;
  }

  //
  h2.fc-toolbar-title {
    font-size: 1.25em;
  }

  .full-calendar-toggle-options {
    margin: 0;

    .full-calendar-toggle-btn {
      cursor: pointer;
      width: 33.3333%;
      text-align: center;
      float: left;
      font-size: 14px;
      text-transform: uppercase;
      padding: 15px;
      transition: background 0.2s ease;

      &:hover {
        background: #0bb68c2e;
      }

      &:active {
        background-color: #0bb68c2e;
        background-size: 100%;
        transition: background 0s;
      }

      &.is-active {
        border-bottom: 2px solid #0bb68c;
        color: #0bb68c;
      }
    }
  }

  .auto-schedule {
    background: #fff;
    // padding: 19px 24px;
    height: 70px;
    width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    color: #3db68b;
    margin-left: 10px;
    float: left;

    &.is-navigate {
      float: right;
      margin-right: 10px;
      margin-left: 0;
    }

    i {
      font-size: 24px;
    }

    opacity: 1;

    &.is-disabled {
      cursor: not-allowed;
      opacity: 0.7;
    }

    &.is-green {
      background: #3db68b;
      float: right;
    }

    &:hover {
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    }

    i {
      &.fa-plus {
        color: #fff;
      }
    }
  }
}

.full-calendar-footer {
  min-height: 70px;

  i.fa-redo-alt {
    position: absolute;
    right: 15px;
    top: 10px;
    font-size: 16px;
    cursor: pointer;
  }

  .in-progress {
    top: 10px;
  }
}

.scheduling-options {

  // margin: 2rem 0;
  // margin-top: 0;
  .add-btn {
    margin: 0;
    margin-right: 20px;
  }

  button {
    width: 100px;
  }

  button:disabled {
    background: #2e9275de;
    cursor: not-allowed;
  }

  .col-md-6 {
    padding: 0;
  }

  .col-md-12 {
    padding: 0;
    text-align: right;
    position: relative;

    span.text-danger {
      float: left;
      padding-top: 5px;
      // position: absolute;
      // bottom: -25px;
      // left: 30%;
    }
  }

  i {
    font-size: 24px;
  }
}

.uprage-plan-banner-content {
  width: 25% !important;
  padding: 15px 5px !important;
  font-size: 14px;
  text-align: center;

  img {
    padding-bottom: 20px;
    width: 250px;
  }

  span.message {
    display: inline-block;
  }
}

.document-due {
  padding: 35px;
  font-size: 14px;
  margin-left: 5px;
  min-height: 70vh;

  .in-progress {
    font-size: 16px;
    padding: 10px 20px;
  }

  .input-start-date {
    padding: 0;
    margin-bottom: 15px;

    input {
      padding: 10px 15px;
    }
  }

  .week-ctrl {
    text-align: center;
    margin-bottom: 35px;

    .contain {
      background: #e5faf4;
      color: #0bb68c;
      padding: 15px 25px;
      font-size: 16px;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      i {
        padding: 0 25px;
      }

      &.disabled {
        background: #cddfda;
      }
    }
  }

  .head-section {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .listing {
    .list-item {
      padding: 20px 0;
      border-bottom: 1px solid #ccc;

      &.no-data {
        border: none;
      }

      &:first-child {
        border-top: 1px solid #ccc;
      }
    }
  }
}

.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  padding: 25px;
  // margin-right: 35px;
  // margin-bottom: 35px;
  height: 350px;
  overflow: scroll;
  max-width: 48%;
  margin: 10px auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.upload-btn {
  margin-top: 15px;

  span {
    padding: 15px 25px;
    border-radius: 3px;
    color: #fff;
    background-color: #444444;
  }
}

.upload-field {
  width: 100%;
  color: #7a7a7a;
  font-size: 12px;
  font-weight: 400;
  border: 1px solid #d8dbe0;
  padding: 11px;
}

a.preview-csv {
  font-size: 12px;
  margin-top: 5px;
}

.basic-single {
  font-size: 14px;
}

.patient-form {
  .text-danger {
    position: absolute;
  }

  .col-md-5 {
    margin-bottom: 15px;
  }
}

/***data tables search***/

/***data tables search***/
#custom_datatable_info {
  display: none;
  padding-bottom: 5px;
}

#custom_datatable_paginate a {
  cursor: pointer;
  font-size: 12px;
  margin: 0;
  color: #c8c6cb;
  font-weight: 400;
  padding: 8px 15px;
  border-right: 1px solid #c8c6cb;
}

#custom_datatable_paginate {
  float: right;
  border: 1px solid #ccc;
  padding: 6px 0;
  margin-top: 5px;
}

.paginate_button.current {
  background: #44af52;
  color: #fff !important;
  font-weight: 600 !important;
}

#custom_datatable_filter {
  width: 250px;
  float: right;
}

i.fas.fa-search.search-top.patients-table {
  right: 35px;
}

#custom_datatable_filter input {
  opacity: 0.7;
  font-weight: 300;
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 0;
  color: #000;
  padding: 0 30px 0 0px;
}

#custom_datatable_filter label {
  color: #fff;
}

#custom_datatable_filter {
  width: 250px;
  float: right;
  margin-bottom: 25px;
}

i.fas.fa-search.search-top {
  position: absolute;
  right: 120px;
  font-size: 14px;
  top: 45px;
  color: #777;
}

.add-btn button {
  background: #2e9275;
  width: auto;
  height: auto;
  font-size: 14px;
  padding: 10px 45px;
  cursor: pointer;
  font-weight: 600;
  border-radius: 5px;
  position: relative;
  top: 0;
  margin: 0;

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}

.add-btn {
  float: left;
  margin-bottom: 20px;

  h1 {
    padding-top: 20px;
    font-weight: 600;
  }
}

#custom_datatable {
  input[type="checkbox"] {
    width: auto;
    height: auto;
    margin-left: 15px;
    cursor: pointer;
  }
}

.table-footer {
  float: right;

  .inplace-confirm {
    margin-right: 20px;

    .confirm-btn-html {
      padding: 3px 0;
    }
  }

  i {
    color: #a6a6a6;
    font-size: 24px;
    cursor: pointer;
  }
}

.delete-selected {
  text-align: right;
  font-size: 14px;

  span.delete-btn {
    cursor: pointer;
    background: #e55353 !important;
    color: #fff !important;
    padding: 10px 7px;
    border-radius: 5px;
  }

  .inplace-confirm .confirm-btn-html {
    padding-top: 8px;
  }
}

/***end***/

.error-message {
  font-size: 14px;
}

// CHAT //
.chat-main {
  .row {
    margin: 0;
  }

  font-size: 14px;
  padding: 15px 0 50px 0;

  h2 {
    font-weight: inherit;
    padding-left: 25px;
  }

  .chat-body {
    margin: 40px;
    margin-bottom: 0;
    background: #f3f3f3;
    padding: 15px 25px;
    padding-left: 0;

    .user-avatar {
      background: #2bbada;
      display: inline-block;
      height: 20px;
      width: 30px;
      color: #fff;
      border-radius: 3px;
      text-align: center;
    }

    .user-name {
      margin-left: 10px;
      text-transform: capitalize;
    }

    .refresh {
      text-align: right;

      i {
        margin-left: 15px;
      }
    }

    .chats {

      .row:first-child,
      h4 {
        padding: 0 15px;
        padding-top: 20px;
        position: relative;

        small {
          position: absolute;
          bottom: -15px;
        }
      }

      padding: 0;

      h4 {
        font-weight: 600;
        margin-top: 2rem;
        margin-bottom: 1rem;
        font-size: 16px;
      }

      .col-md-6 {
        padding: 0;
      }
    }

    .dot {
      height: 8px;
      width: 8px;
      background-color: #bbb;
      border-radius: 50%;
      display: inline-block;
      margin-right: 3px;

      &.active-user-dot {
        background: #44af52;
      }

      &.message-count-dot {
        height: 17px;
        width: 16px;
        float: right;
        padding-left: 4px;
        font-size: 12px;
        background: #000;
        color: #fff;
      }
    }

    .listing {
      height: 400px;
      overflow: scroll;

      &::-webkit-scrollbar {
        display: none;
      }

      .list-item {
        padding: 15px 0;
        padding-right: 13px;
        cursor: pointer;
        padding-left: 15px;

        &:hover {
          background: #e9e7e7;
        }

        &.activeMessage {
          background: #e9e7e7;
        }
      }
    }

    .typingNotification {
      font-size: 12px;
      position: absolute;
      top: 0;
    }

    .messages {
      background: #fff;
      position: relative;
      // padding: 10px 20px;
      padding: 0;
      cursor: pointer;

      .input-container {
        padding: 10px 30px;
        position: relative;

        i {
          color: #44af52;
          position: absolute;
          right: 45px;
          top: 30px;
        }
      }

      textarea {
        width: 100%;
      }

      h4 {
        font-weight: 400;
        font-size: 20px;
        margin: 15px 30px;
        margin-bottom: 30px;
        text-transform: capitalize;
      }

      .message {
        transition: 0.4s;
        padding: 15px;

        .message-time {
          color: #a0a0a0;
        }

        &:hover {
          background: #f1f1f1;
        }
      }
    }

    .chat-block {
      padding: 20px 0;
      border-top: 1px solid gainsboro;
      height: 400px;
      overflow: scroll;

      &::-webkit-scrollbar {
        display: none;
      }

      .line-divide {
        border-bottom: 1px solid gainsboro;
      }

      .sticky-date {
        text-align: center;
        position: sticky;
        top: 0;
        z-index: 1;
        top: -20px;
        margin-bottom: 15px;
      }
    }

    .active-message-thread-date {
      background: #000;
      z-index: 1;
      color: #fff;
      width: 180px;
      display: inline-block;
      text-align: center;
      padding: 5px 15px;
      border-radius: 20px;
      position: absolute;
      left: 37%;
      top: 0;
    }
  }

  .welcomeScreen {
    padding: 10px 25px !important;
    text-align: left;

    h3 {
      font-size: 20px;
    }

    p {
      color: #777;
    }
  }
}

// END //
// Live Tracking //
.live-tracking {
  .row {
    margin: 0;
  }

  font-size: 14px;
  padding: 15px 0 50px 0;

  h2 {
    font-weight: inherit;
    padding: 10px 25px;
    margin-bottom: 0;
  }

  .tracking-map {
    border-radius: 10px;
    margin: 30px 25px;
    border: 1px solid #ccc;
    padding: 15px 20px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);

    h2 {
      padding-left: 0;
    }

    .map-space {
      position: relative;
      height: 450px;

      #map {
        canvas {
          border-radius: 15px;
        }
      }
    }

    h2 {
      position: relative;
    }

    .map-search {
      float: right;
      position: absolute;
      right: 0;
      bottom: 10px;
      width: 200px;
    }

    .location-denied {
      padding: 10px 0;

      small {
        font-size: 12px;
      }
    }
  }

  tr {
    transition: 0.4s;
  }

  table thead tr {
    th {
      background: #f1f1f1;
    }
  }

  tr:hover {
    cursor: pointer;
    background: #f1f1f1;
  }

  .marker-content {
    width: 150px;
    padding: 5px;
    text-transform: capitalize;

    label {
      text-transform: inherit;
      display: inline;
      font-weight: 600;
    }

    // .link-schedule{float: right;}
  }
}

// END //
.patient-details {
  .cancel-btn {
    margin-top: 25px;
  }
}

.footer-section-patient {
  p {
    width: 25% !important;
    float: left;
    text-align: center;
    margin: 10px 0 !important;

    img {
      border-radius: 26px;
      box-shadow: 0 4px 8px 0 hsla(0, 0%, 80%, 0.8);
      width: 56px;

      &:hover {
        box-shadow: 0 8px 16px 0 hsla(0, 0%, 80%, 0.8);
      }
    }

    span {
      color: #535353;
      font-size: 14px;
      width: 100%;
      display: inline-block;
      margin: 5px 0 0;
    }
  }

  .auto-schedule {
    float: right;
    margin-top: 15px;
    background: #fff;
    padding: 17px;
    border-radius: 50%;
    box-shadow: 0 4px 8px 0 hsla(0, 0%, 80%, 0.8);
    cursor: pointer;
    color: #3db68b;
    margin: 10px 25px;
    float: left;

    &:hover {
      box-shadow: 0 8px 16px 0 hsla(0, 0%, 80%, 0.8);
    }

    img {
      width: 28px;
    }
  }
}

.close-btn {
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 18px;
}

.visit-frequency {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 14px;
  text-align: center;

  .inplace-confirm {
    float: right;
  }

  label:first-child {
    display: block;
    font-size: 16px;

    span {
      float: left;
      font-size: 12px;
      position: absolute;
      left: 20px;
      top: 15px;
      font-style: oblique;
    }
  }

  .radio-option-grp {
    padding: 15px;

    .form-check {
      display: inline;
      margin: 0 10px;

      &.active {
        color: #0bb68c;
      }

      label {
        margin-left: 10px;
      }
    }
  }

  .selector {
    box-shadow: 2px 4px 3px #f2f9f2;
    border: 1px solid #f2f9f2;
    padding: 10px;
    margin: 0 15px 18px;
    border-radius: 5px;
    text-align: left;

    .increment-decrement {
      display: inline-block;
      float: right;

      i {
        border: 1px solid red;
        border-radius: 50%;
        font-size: 12px;
        padding: 2px;
        margin: 0 10px;

        &.fas.fa-plus {
          color: #0bb68c;
          border-color: #0bb68c;
        }

        &.fas.fa-minus {
          color: #dd726b;
          border-color: #dd726b;
        }
      }
    }

    &.select-clinician {
      padding: 0;
    }
  }

  .frequency-listing {
    height: 35vh;
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    .list-item {
      margin-bottom: 10px;

      // display: inline-block;
      // width: 33%;
      // float: left;
      .frequency {
        font-weight: 600;
        color: #0bb68c;
        padding: 10px 15px;
        background: #e5faf4;
        border-radius: 10px;
        display: inline-block;

        &.is-clinician-name {
          background: #fff;
          color: #000;
        }
      }

      i {
        margin-left: 10px;
        color: #dd726b;
        vertical-align: 3px;
      }
    }
  }

  .go-back-bottom {
    float: left;
    margin-left: 20px;
    box-shadow: 10px 10px 10px #cccccc54;
    font-size: 14px;

    button {
      width: 100%;
      border-radius: 5px;
      margin: 0 20px 0 0px;
      height: 40px;
      font-size: 14px;
      font-weight: 400;
      color: #fff;
      border: none;

      &.go-back {
        background: #ec6b66;
      }
    }
  }

  span.plus-bottom,
  span.tick-bottom {
    height: 45px;
    width: 45px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    padding: 10px 0;
    font-size: 20px;
    background: #0bb68c;
    color: #fff;
    margin: 0 15px;
    box-shadow: 2px 3px 5px #d5f0e9;
  }

  span.plus-bottom {
    background: #0bb68c;
    color: #fff;
    margin-bottom: 15px;
    margin-right: 15px;

    &.in-progress {
      background: #0bb68c89;
    }
  }

  &.recert {
    position: relative;

    .recert-body {
      height: 60vh;
      overflow: scroll;

      &::-webkit-scrollbar {
        display: none;
      }

      .table {
        width: 92%;
        text-align: left;
        margin-left: 20px;

        th {
          color: #797979;
          border: none;
        }

        td {
          cursor: pointer;
          border: none;
          // border-bottom: 1px solid #ddd;
          padding: 15px 8px;
        }
      }
    }

    span.plus-bottom {
      position: absolute;
      right: 10px;
      background: #0bb68c;
      font-weight: 400;
      color: #fff;
      bottom: -5px;
      z-index: 9;
    }

    .recert-form {
      padding: 0 25px;
      height: 60vh;
      overflow: scroll;

      &::-webkit-scrollbar {
        display: none;
      }

      small {
        float: none;
        position: inherit;
      }

      label {
        display: inline-block;
        font-size: 12px;
        text-align: left;
      }

      h4 {
        text-align: left;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 5px;

        span {
          float: right;
        }
      }

      .inplace-confirm .confirm-btn-html {
        padding-top: 5px;
      }
    }

    .buttons {
      position: sticky;
      bottom: 0;
      margin-bottom: 0;
      background: #fff;
      padding: 10px 0;
    }
  }
}

.dashboard {
  font-size: 14px;

  .dashboard-card {
    label {
      text-transform: none;
    }

    .row {
      margin: 0;
    }

    .data {
      padding: 10px 0 !important;
    }

    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    height: 100px;
    border-radius: 15px;

    &:hover {
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    }

    &.visits-scheduled {
      background-color: #ed5654;
    }

    &.clinicians {
      background-color: #64de72;
    }

    &.missed-visits {
      background-color: #6cb0e8;
    }

    &.last-visits {
      background-color: #b57af2;
    }
  }

  .overview-section {
    margin: 10px 0;

    .dashboard-card {
      max-width: 24%;
      padding: 15px 25px;
      margin: auto;
      border-radius: 15px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      [class^="col-"] {
        padding: 0;
      }

      .data {
        label {
          font-size: 28px;
          font-weight: 600;
          margin: 0;
          color: #fff;
          position: relative;

          //   padding: 25px 0;
          span:not(.count-up) {
            font-size: 13px;
            font-weight: 400;
            display: block;
            text-transform: none;
            // position: absolute;
            // top: 15px;
          }
        }
      }

      .image {
        text-align: center;
        border: 2px solid #fff;
        border-radius: 50%;
        width: 75px;
        height: 75px;
        position: relative;
        margin: auto;

        img {
          height: 45px;
          width: auto;
          position: absolute;
          left: 50%;
          transform: translate(-50%, 13px);
        }
      }
    }
  }

  .bar-graph {
    overflow: hidden;
    border-radius: 10px;
    margin: 30px 0;
    border: 1px solid #ccc;
    // padding: 0 20px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;

    h2 {
      font-weight: inherit;
      padding: 15px 20px;
      margin-bottom: 0;
      position: relative;
      font-size: 16px;
    }

    .head {
      font-size: 16px;
      border-bottom: 1px solid #ccc;
      font-weight: 600;
      padding: 10px 20px;

      .heading {
        padding-top: 15px;
      }
    }

    .table-drop-down {
      float: right;
      position: absolute;
      right: 15px;
      bottom: 10px;
      width: 150px;

      select {
        height: 30px;
      }
    }

    th,
    td {
      padding-left: 20px;
    }

    tr th {
      background-color: #f5f5f5;
    }
  }

  .toggle-options {
    margin: 0;

    .toggle-btn {
      cursor: pointer;
      width: 33.3333%;
      text-align: center;
      float: left;
      font-size: 14px;
      font-weight: 400;
      text-transform: uppercase;
      padding: 15px;
      transition: background 0.2s ease;

      &.is-active {
        background-color: #44af52;
        color: #fff;
        border-radius: 5px;
      }
    }
  }

  .table-aside-section {
    .overview-section .dashboard-card {
      .data {
        padding: 0 !important;
      }

      height: 120px;
      max-width: 100%;
      padding: 20px 15px;
      margin-bottom: 2rem;

      label {
        color: inherit;

        // padding: 30px 0;
        span {
          top: 0;
        }

        strong {
          font-weight: 600;

          &.green-text {
            color: #44af52;
          }

          &.red-text {
            color: #ed5654;
          }
        }
      }

      .red-border {
        border-color: #ed5654;
      }

      .green-border {
        border-color: #44af52;
      }
    }

    .overview-section {
      margin: 30px 0;
    }
  }
}

#__react-alert__ {
  div:first-child {
    top: 75px !important;
    z-index: 9999999;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

._alert {
  z-index: 9999999;
  background: #fff;
  font-size: 14px;
  padding: 10px;
  width: 300px;
  // border: solid 2px gainsboro;
  border-left: 3px solid #44af52;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

  button {
    float: right;
    width: 20px;
    background: transparent;
    border: none;
    font-size: 13px;
  }

  &.error {
    border-left-color: #de5555;
  }
}

.payment-card-form {
  font-size: 14px;

  [class^="col-"] {
    margin-bottom: 20px;
  }

  h2 {
    span {
      float: right;
      font-weight: 400;
      font-size: 16px;
    }
  }
}

.text-btn {
  font-weight: 900 !important;

  &:hover {
    text-decoration: underline;
  }
}

.fc-button-primary {
  color: #0bb68c !important;
  background: #e5faf4 !important;
  border-color: #e5faf4 !important;

  .fc-icon {
    font-weight: 900 !important;
  }
}

.flex-box {
  display: flex;
  justify-content: center;
}

.inplace-edit-container {
  position: relative;

  .inplace-edit {
    position: absolute;
    left: 10px;
    display: flex;
    justify-content: space-evenly;
    width: 100%;

    input {
      width: 75%;
      padding: 5px;
    }
  }

  span.tick-bottom {
    background: #0bb68c;
    color: #fff;
    opacity: 1;
    // margin: 0 15px;
    box-shadow: 2px 3px 5px #d5f0e9;
    height: 40px;
    width: 40px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    padding: 6px 0;
    font-size: 20px;
  }
}

.popup-overlay:not(.uprage-plan-banner-overlay) {
  z-index: 999999 !important;
}

.rdt {
  height: 100%;

  input {
    height: 100%;

    &:read-only {
      background: inherit;
    }
  }

  .rdtPicker {
    th.rdtSwitch {
      width: 100% !important;
      padding-left: 0 !important;
    }

    td {
      padding: 15px;
    }

    .rdtPrev,
    .rdtNext {
      padding: 15px;
      vertical-align: inherit;
    }
  }
}

.data-fetching {
  display: inline-block;
  margin-left: 10px;
}

.signup {
  font-size: 14px;
  padding: 0;

  .cust-container {
    padding: 0;
    margin: 0;

    // overflow: hidden;
    // max-height:100vh;
    // margin-right: 40px;
    .welcome-box {
      width: 85%;
      margin: auto;
      text-align: center;

      h3 {
        font-weight: 700;
        font-size: 27px;
        color: #000 !important;
        // margin-top: 70px;
      }

      p {
        color: #6e6e6e !important;
        font-weight: 600;
        font-size: 16px;
      }
    }

    .img-holder {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 0;

      .over-img-text {
        color: #fff;
        position: absolute;
        top: 30%;
        left: 40%;
        transform: translate(-50%, -50%);

        h1 {
          border-bottom: 1px solid;
          margin-bottom: 15px;
          font-size: 30px;
        }

        p {
          font-size: 27px;
          font-weight: 700;
        }
      }
    }

    .field-holder {
      display: flex;

      .field-wrapper {
        width: 80%;
        margin: auto;
      }
    }
  }

  img {
    width: 100%;
    height: auto;
  }

  // [class^="col-"]{
  //     padding: 0;
  // }
  input,
  select {
    border-radius: 10px;
    height: 50px;
    // margin-bottom: 2.5rem;
  }

  select {
    width: 100%;
    font-weight: 400;
    padding: 0 5px;
    color: #7a7a7a;
    margin-bottom: 25px;
  }

  form div.input-controller {
    margin-bottom: 2.5rem;
  }

  .text-danger {
    position: absolute;
    font-size: 13px;
    margin-left: 15px;
  }

  button {
    background-color: #52b78d;
    border-radius: 12px;
    padding: 14px 30px;
    height: auto;
    width: auto;
    margin: 0;
    margin-left: 15px;
  }

  .alternative-opt {
    font-size: 16px;
    text-decoration: underline;
    position: absolute;
    right: 20px;
    top: 25px;
  }

  // Thank You
  .thank-you {
    position: relative;
    height: 100vh;
    overflow: hidden;

    .thank-you-content {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      color: #0bb68c;
      text-align: center;

      i {
        font-size: 90px;
      }

      h1 {
        font-size: 60px;
        font-weight: 600;
        margin: 0;
        letter-spacing: 1px;
      }

      p {
        font-size: 20px;
        color: #8c929f;
        margin: 0;
        letter-spacing: -1px;

        &.cust-bold {
          font-weight: 600;
          letter-spacing: 1px;
        }
      }

      span {
        display: block;
        margin-top: 3.5rem;
        color: #8c929f;
      }
    }
  }
}

.cert-period-btn {
  width: 200px;
  height: 40px;
  color: #3cb68e !important;
  border: 1px solid #3cb68e;
  background-color: #e7f8f4;
  display: flex !important;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  border-radius: 3px;
}

.cancel-sub {
  font-size: 13px !important;
  color: #b03434 !important;
  position: absolute;
  right: 0;
}

.cancel-sub-confirm {
  padding: 0 !important;
}

.fc-event {
  border-width: 4px !important;
}

.freq-view {
  width: 300px;
  background: #fff;
  color: #535353;
  border: none;
  font-size: 14px;
  border-radius: 7px;
  box-shadow: 5px 10px 10px gainsboro;
  text-align: left;

  .freq-wrapper {
    border: 2px solid gainsboro;
    border-top: none;
    padding: 10px 15px;

    .item {
      margin-bottom: 0.5rem;
    }

    label {
      font-weight: 900;
      margin: 0;
      text-transform: inherit;
      font-size: 14px;
    }

    span {
      display: block;
    }

    &.cust-loader {
      height: 100px;
    }
  }
}

.tippy-content {
  padding: 0 !important;
  background: none !important;

  h2 {
    opacity: 0.7;
    font-weight: 600;
    font-size: 15px;
    background: #e7f8f4;
    color: #3dbb94;
    display: flex;
    justify-content: center;
    padding: 7px 0;
    border: 2px solid #bee6cd;
    border-radius: 10px 10px 0 0;
    margin: 0;
  }
}

.tippy-box {
  background: none !important;
}

.tippy-box[data-placement^="right"]>.tippy-arrow::before {
  left: -12px !important;
  border-width: 20px 20px 20px 0 !important;
  top: -24px;
}

.sticky-comp-caseload {
  position: sticky;
  top: 0;
  top: 10%;
  z-index: 999;
  padding-top: 45px;
  padding-bottom: 20px;
  background: #fff;
  margin: 0;
  box-shadow: 0 -15px 0 #fff;
  // [class^="col-"] {
  //     padding: 0;
  //   }
}

// MEDIA

@media only screen and (max-width: 768px) {

  /* For mobile phones: */
  .signup {
    .cust-container {
      .welcome-box {
        h3 {
          font-size: 27px;
        }

        p {
          font-size: 16px;
        }
      }

      .img-holder {
        .over-img-text {
          top: 50%;
          left: 50%;
          width: 90%;
          text-shadow: 1px 1px 1px #928e8e;

          h1 {
            font-size: 24px;
          }

          p {
            font-size: 18px;
          }
        }
      }

      .field-holder {
        .field-wrapper {
          width: 90%;
        }
      }
    }

    .text-danger {
      font-size: 13px;
    }

    .alternative-opt {
      font-size: 16px;
    }

    // Thank You
    .thank-you {
      .thank-you-content {
        i {
          font-size: 80px;
        }

        h1 {
          font-size: 50px;
        }

        p {
          font-size: 15px;
        }
      }
    }
  }

  // .sticky-comp-caseload{
  //     top:0!important;
  // }
  .auth__success-main-container {

    .auth__success-left-container,
    .auth__success-right-container {
      display: none;
    }

    .auth__success-content-container {
      width: 100%;
      padding: 0 20px;
      //background: url("../../public/images/success-left.png") no-repeat left;
    }
  }
}

.address-popup {
  padding-bottom: 0;

  .submit_row {
    height: 100px;
    display: flex;
    align-items: center;
  }

  input {
    height: 50px;
    border-radius: 10px;
  }

  button {
    background: #2e9275;

    &:disabled {
      cursor: not-allowed;
    }
  }
}

.split-outer {
  background-image: linear-gradient(-236deg, red 50%, yellow 50%);
  height: 24px;
  width: 24px;
  border-radius: 9px;
  display: inline-block;
  //     position: relative;
  //     display: inline;
  //     span{
  //         position: absolute;
  //         color: #fff;
  //         font-weight: 600;
  //         z-index: 1;
  //         transform: translate(5px, 4px);
  //         font-size: 11px;
  //     }
  //     .split-visit{
  //         position: absolute;
  //         color: white;
  //         height: 24px;
  //         width: 24px;
  //         border-radius: 9px;
  //         overflow: hidden;
  //         transform: rotate(45deg);
  //         display: inline-grid;
  //     .cust-primary{
  //         background: red;
  //     }
  //     .cust-secondary{
  //         background: blueviolet;
  //     }
  // }
}

.disabled-ico {
  cursor: not-allowed;
  opacity: 0.7;
}

.pay-method-redirect {
  color: #fff;
  margin-top: 1.5rem;

  button {
    border: none;
    box-shadow: 10px 10px 10px #cccccc54;

    &:active {
      border: none;
    }

    &:focus,
    &:focus-visible {
      outline: none;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.user-setting-text {
  /* Display Extra Small/Bold */

  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */

  /* Gray neutral/900 */

  color: #111927;
}

.user-info-text {
  /* Text Base/Medium */

  font-family: "Inter";
  font-style: normal;
  font-size: 16px;
  line-height: 19px;

  /* Gray/800 */

  color: #1d2939;
}

.user-info-text-secondary {
  /* Text Small/Regular */

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  /* Gray neutral/500 */

  color: #6c737f;
}

.update-avatar-button {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;

  &:active {
    outline: none !important;
  }
}

.remove-avatar-button {
  /* Text Small/Medium */

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Gray neutral/500 */

  color: #6c737f;

  &:active {
    outline: none !important;
  }
}

.user-home-address-text {
  /* Text Extra Large/Medium */

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;

  /* Gray neutral/800 */

  color: #1f2a37;
}

.change-user-plan {

  &:focus,
  &:focus-visible {
    outline: none !important;
  }

  width: 117px;
  height: 17px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-decoration-line: underline;

  /* Gray neutral/600 */

  color: #4d5761;
}

.weekday-user-button {
  display: block;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  margin: 5px 1%;
  /* Text sm/Medium */

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: center;

  /* Gray neutral/800 */

  color: #1f2a37;

  text-align: center;

  &:not(:disabled):hover {
    background-color: #72c6a9;
    color: #1f2a37;
  }

  &:disabled:hover {
    pointer-events: none;
    background-color: none !important;
    color: #1f2a37;
  }
}


.weekday-selected {
  background: #0bb68c;
  color: white;

  &:hover {
    cursor: pointer;
    background: #088c6c !important;
    color: white !important;
  }
}

/*POP UP*/
.delete_patient_popup {
  position: relative;
  margin: 0 auto;
  // top: calc(calc(100vh - 309px) / 2);
  border-radius: 12px;

  h2 {
    //styleName: Text Extra Large/Semibold;
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    color: #0bb68c;
  }

  h3 {
    //styleName: Text Extra Large/Bold;
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    color: #1f2a37;
  }

  p {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    color: #4d5761;

    span {
      font-family: Inter;
      font-size: 16px;
      font-weight: 700;
      line-height: 19px;
      letter-spacing: 0em;
    }
  }

  .pop_btn {
    //styleName: Text Extra Small/Medium;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0.01em;
    color: #4d5761;
  }

  .pop_delete_btn {
    background: #b42318;
    color: #fff;
    width: 137px;

  }

  .pop_save_btn {
    background: #0BB68C;
    color: #fff;
  }
}

.login_logo {
  height: 50px;
  width: 214px;
}

a,
button {
  cursor: pointer;
}

.patient_record {
  .title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #111927;
  }

  .subtitle-gray {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #6c737f;
  }
}

.card-detail-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #1f2a37;
}

.patient_record_subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #111927;
  padding-top: 20px;

  h6 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #4d5761;
    padding-top: 12px;
    padding-bottom: 24px;
  }
}

.separator>* {
  display: inline-block;
  vertical-align: middle;
}

.separator {
  text-align: center;
  border: 0;
  white-space: nowrap;
  display: block;
  overflow: hidden;
  padding: 0;
  margin: 32px 0 24px 0;
  //styleName: Text Small/Regular;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;


  & .title {
    width: 20px;
    height: 17px;

    /* Text Small/Regular */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    text-align: center;

    /* Gray neutral/600 */

    color: #4D5761;
  }
}

.separator:before,
.separator:after {
  content: "";
  height: 1px;
  width: 50%;
  background-color: #e5e7eb;
  margin: 0 5px 0 5px;
  display: inline-block;
  vertical-align: middle;
}

.separator:before {
  margin-left: -100%;
}

.separator:after {
  margin-right: -100%;
}

.social-login-container {
  text-align: center;
  text-align: -webkit-center;

  margin: 0 32px;
}

form.sign-form .social-login-container{
  max-width: 386px;
}

.social-login {
  width: 70%;
  min-width: 100%;
  margin-bottom: 16px;

  button {
    border-radius: 8px !important;
  }

  & .social-first-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #6C737F;
  }

  & .social-second-title {
    cursor: pointer;
    margin-left: 3px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #6C737F;
  }

}

.disable {
  pointer-events: none;
  opacity: .5;
}

.social-login button {
  box-shadow: 0px 1px 2px 0px #1018280D !important;
  height: 44px !important;
  width: 322px;
  font-family: Inter;
  font-size: 16px !important;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;

}

.social-login button div {
  width: auto !important;
  justify-content: center;
  align-items: center;
}

.social-login button div:last-child {
  margin-left: 14px
}

.social-login button div svg {
  height: 28px !important
}

.social-login.social-google button {
  border: 1px solid #D0D5DD !important;
  background: #FFFFFF !important;
  color: #344054 !important
}

.social-login.social-facebook button {
  background: #1877F2 !important;

  &:hover,
  &:focus {
    background: #0C63D4 !important
  }
}

.social-login.social-linkedin button {
  background: #0073B2 !important;

  &:hover,
  &:focus {
    background: #015F92 !important;
  }
}

.social-login.social-linkedin>button>div>div:nth-child(1) {
  background: #FFFFFF;
  border-radius: 4px;
}

.social-login>button>div>div:nth-child(1),
.social-login>button>div>div:nth-child(1) svg {
  max-height: 24px;
  max-width: 24px;

}

.social-login.social-linkedin>button>div>div:nth-child(1) svg path {
  fill: #0073B2;

}

.social-login.social-linkedin>button>div>div:nth-child(1) svg {
  padding: 2px;
}

.social-login.social-facebook>button>div>div:nth-child(1) svg {
  border-radius: 50%;
}

.social-login.social-other {
  text-align: start;
}