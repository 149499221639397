.patient-search-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    margin-top: 40px;
    margin-right: 0px;
    margin-left: 0px;
    margin-bottom: 19px;

    width: 99%;
    height: 104px;
}


.patient-search-container .search-box {
    /* box-sizing: border-box; */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    margin-left: 30px;
    margin-right: 14px;
    margin-bottom: 8px;
    gap: 8px;

    flex-grow: 1;

    background: #FFFFFF;

    border: 1px solid #D2D6DB;
    height: 44px;

    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.patient-search-container .search-box select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    width: 90%;
    border-style: none;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #6C737F;
    background-color: #FFFFFF;

    -ms-word-break: normal;
    word-break: normal;

    flex-grow: 1;
    cursor: pointer;
}

.patient-search-container .search-box select::-ms-expand {
    display: none;
}

.font-14, .font-16 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
}

.font-16 {
  font-size: 16px;
}

.font-12 {
  font-size: 12px;
}

.rounded-8 {
  border-radius: 8px;
}

.height-22 {
  height: 22px;
}

.patient-search-continer .search-box input {
    width: 95%;
    border-style: none;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #6C737F;
    flex-grow: 1;
}

.calendar-container {
    margin-top: 40px;
    margin-right: 32px;
    margin-left: 0px;
    margin-bottom: 70px;
    background: #FFFFFF;
    border-radius: 12px;
    //height: 700px;
    height:92%;
    max-height:2700px

}

.patient-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    padding-top: 22px;
    gap: 8px;
    margin: 0px;
    height: 550px;
    overflow-y: auto;
    overflow-x: hidden;
}

::-webkit-scrollbar {
    width: 4px;
}

::-webkit-scrollbar-thumb {
    background: #0BB68C;
}

::-webkit-scrollbar-track {
    background: #C3C8CF;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    background-clip: padding-box;
}

.patient-container .patient-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 16px;
    margin-left: 30px;
    margin-right: 0px;
    gap: 8px;

    height: 51px;

    background: #FFFFFF;
    border-radius: 8px;

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;

    /* text selecton */
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}

.patient-container .patient-box-group {
    padding: 8px 16px;
    margin-left: 45px;
    margin-right: 0px;

    height: 51px;

    border-radius: 8px;

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;

    /* text selecton */
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}

.patient-container .patient-box:hover {
    box-sizing: border-box;
    background: #EBFFFA;
    border: 1px solid #0BB68C;
}

.patient-container .patient-box:active {
    box-sizing: border-box;
    background: #BBFBEB;
    border: 1px solid #0BB68C;
}

.patient-container .patient-box .title {
    /* width: 210px; */
    height: 15px;

    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    letter-spacing: 0.03em;

    color: #111927;

}

.patient-container .patient-box .description {
    /* width: 210px; */
    height: 12px;

    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;

    color: #4D5761;
}