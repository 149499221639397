
.patient-date-holder {
  //     &.errorDate{
  //         border: 1px solid #ce195a;
  //     }
  //     &.successDate{
  //         border: 1px solid $primary !important;
  //     }
  //   display: flex;
  //   flex-direction: row;
  //   align-items: center;
  //   padding: 12px;
  //   height: 48px;
  //   background: #ffffff;
  //   border: 1px solid #d2d6db;
  //   box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  //   border-radius: 8px;
  
      & svg{
          position: absolute;
    top: 14px;
    right: 12px;
    font-family: "Font Awesome 6 Pro";
    font-style: normal;
    font-weight: 400 !important;
    font-size: 16px;
    line-height: 16px;
    /* identical to box height */
  
    display: flex;
    align-items: center;
    text-align: center;
  
    /* Gray neutral/500 */
  
    color: #6c737f;
      }
  
    .date-title {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #111927;
      text-align: center;
      text-transform: capitalize;
      padding-top: 2px;
    }
  }
  
  .dialog-datepicker-patient {
    width: 100%;
    min-width: 320px;
    height: 418px;
    position: absolute;
    z-index: 9999;
    top: 50px;
    left: -5px;
    background-color: white;
  
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
      0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    border-radius: 12px;
  
    .rdp {
      padding: 20px 12px 12px;
      margin: 0px;
      width: 100%;
      height: 100%;
  
      .rdp-months {
        height: 100%;
        width: 100%;
  
        .rdp-month {
          height: 100%;
          width: 100%;
  
          .rdp-caption {
            display: flex;
            justify-content: center;
            flex-direction: row;
            align-items: center;
            padding: 2px 4px;
            gap: 57px;
            height: 28px;
  
            .rdp-button:hover {
              background: #ffffff;
              filter: none;
            }
  
            .rdp-button:active {
              background: #ffffff;
              border-color: #ffffff;
              filter: none;
            }
  
            .rdp-button:focus {
              background: #ffffff;
              border-color: #ffffff;
              filter: none;
            }
  
            .rdp-caption_label {
              position: absolute;
              text-transform: capitalize;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              /* Text Base/Medium */
  
              font-family: "Inter";
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 19px;
              text-align: center;
  
              /* Gray modern/800 */
  
              color: #202939;
            }
  
            .rdp-nav {
              width: 100%;
              display: flex;
              justify-content: space-between;
            }
  
            .rdp-nav_button {
              color: #1f2a37;
            }
  
            //   .rdp-nav_button_previous {
            //     position: absolute !important;
            //     bottom: -20px;
            //     left: 0px;
            //   }
          }
  
          .rdp-table {
            width: 100%;
            max-width: 100%;
            height: calc(100% - 28px);
            margin: 0px;
  
            font-family: "Inter";
            font-style: normal;
  
            .rdp-head {
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              text-align: center;
              color: #202939;
              height: 40px;
              text-transform: capitalize;
  
              .rdp-head_cell {
                text-transform: capitalize;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
              }
            }
  
            .rdp-tbody {
              font-weight: 400;
              font-size: 14px;
              line-height: 17px;
              text-align: center;
              color: #364152;
              width: 100%;
  
              .rdp-row {
                height: 40px;
                max-height: 40px;
              }
  
              .rdp-button:hover {
                background: #aee9da;
                filter: none;
              }
  
              .rdp-button:active {
                background: rgb(104, 207, 182);
                border-color: rgb(104, 207, 182);
                filter: none;
              }
  
              .rdp-button:focus {
                background: #ffffff;
                border-color: #ffffff;
                filter: none;
              }
  
              .rdp-day_selected {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 39px;
                height: 39px;
                padding: 10px 15px;
                background: #0bb68c !important;
                border-radius: 20px;
              }
            }
          }
        }
      }
    }
  }
  .patient-datetime-holder{
  //     &.errorDate{
  //         border: 1px solid #ce195a;
  //     }
  //     &.successDate{
  //         border: 1px solid $primary !important;
  //     }
  //   display: flex;
  //   flex-direction: row;
  //   align-items: center;
  //   padding: 12px;
  //   height: 48px;
  //   background: #ffffff;
  //   border: 1px solid #d2d6db;
  //   box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  //   border-radius: 8px;
  
      & svg{
          position: absolute;
    top: 14px;
    right: 12px;
    font-family: "Font Awesome 6 Pro";
    font-style: normal;
    font-weight: 400 !important;
    font-size: 16px;
    line-height: 16px;
    /* identical to box height */
  
    display: flex;
    align-items: center;
    text-align: center;
  
    /* Gray neutral/500 */
  
    color: #6c737f;
      }
  
    .date-title {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #111927;
      text-align: center;
      text-transform: capitalize;
      padding-top: 2px;
    }
  }
  
  .dialog-datetimepicker-patient {
    width: 100%;
    min-width: 320px;
    min-height: 518px;
    height: 725px;
    position: absolute;
    z-index: 9999;
    top: 50px;
    left: -5px;
    background-color: white;
  
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
      0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    border-radius: 12px;
  
    .rdp {
      padding: 20px 2px 0px 0px;
      margin: 0px;
      width: 100%;
      height: 100%;
  
      .rdp-months {
        height: 100%;
        width: 100%;
  
        .rdp-month {
          height: 100%;
          width: 100%;
  
          .rdp-caption {
            display: flex;
            justify-content: center;
            flex-direction: row;
            align-items: center;
            padding: 2px 4px;
            gap: 57px;
            height: 28px;
  
            .rdp-button:hover {
              background: #ffffff;
              filter: none;
            }
  
            .rdp-button:active {
              background: #ffffff;
              border-color: #ffffff;
              filter: none;
            }
  
            .rdp-button:focus {
              background: #ffffff;
              border-color: #ffffff;
              filter: none;
            }
  
            .rdp-caption_label {
              position: absolute;
              text-transform: capitalize;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              /* Text Base/Medium */
  
              font-family: "Inter";
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 19px;
              text-align: center;
  
              /* Gray modern/800 */
  
              color: #202939;
            }
  
            .rdp-nav {
              width: 100%;
              display: flex;
              justify-content: space-between;
            }
  
            .rdp-nav_button {
              color: #1f2a37;
            }
  
            //   .rdp-nav_button_previous {
            //     position: absolute !important;
            //     bottom: -20px;
            //     left: 0px;
            //   }
          }
  
          .rdp-table {
            width: 100%;
            max-width: 100%;
            // height: calc(100% - 28px);
            margin: 0px;
  
            font-family: "Inter";
            font-style: normal;
  
            .rdp-head {
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              text-align: center;
              color: #202939;
              height: 40px;
              text-transform: capitalize;
  
              .rdp-head_cell {
                text-transform: capitalize;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
              }
            }
  
            .rdp-tbody {
              font-weight: 400;
              font-size: 14px;
              line-height: 17px;
              text-align: center;
              color: #364152;
              width: 100%;
  
              .rdp-row {
                height: 40px;
                max-height: 40px;
              }
  
              .rdp-button:hover {
                background: #aee9da;
                filter: none;
              }
  
              .rdp-button:active {
                background: rgb(104, 207, 182);
                border-color: rgb(104, 207, 182);
                filter: none;
              }
  
              .rdp-button:focus {
                background: #ffffff;
                border-color: #ffffff;
                filter: none;
              }
  
              .rdp-day_selected {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 39px;
                height: 39px;
                padding: 10px 15px;
                background: #0bb68c !important;
                border-radius: 20px;
              }
            }
          }
        }
      }
    }
  }
  
  .date-picker-footer {
    border-top: 1px solid #e6e8ed;
    margin-top: 10px;
  
  
    & .time-picker-title{
      margin-top: 10px;
      font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  
  /* Gray modern/800 */
  
  color: #202939;
    }
  }
  
  .MuiDialogActions-root,
  .css-1hbyad5-MuiTypography-root,
  .css-1bzq5ag,
  .css-z3au5x-MuiButtonBase-root-MuiIconButton-root-MuiPickersToolbar-penIconButton,
  .MuiPickersToolbar-penIconButton{
    display: none !important;
  }
  // .css-j3g793-MuiCalendarOrClockPicker-root {
  //   display: flex !important;
  //   flex-direction: column !important;
  //   align-items: center !important;
  //   justify-content: center !important;
  // }
  // .css-jhsfre-MuiGrid-root-MuiPickersToolbar-content{
  //   margin-left: 80px;
  //   min-width: 300px !important;
  //   display: flex !important;
  //   align-items: center !important;
  //   justify-content: center !important;
  //   align-content: center !important;
  // }
  // .css-1pjrtiy-MuiPickersToolbar-root-MuiTimePickerToolbar-root{
  //   display: flex !important;
  //   justify-content: center !important;
  //   min-width: 300px !important
  // }
  // .css-xelq0e-MuiPickerStaticWrapper-content {
  //   min-width: 280px;
  // }
  // .css-pbg3za-MuiGrid-root-MuiPickersToolbar-content {
  //   min-width: 300px !important;
  //   display: flex !important;
  //   align-items: center !important;
  //   justify-content: center !important;
  //   align-content: center !important;
  // }
  // .css-1m5r8g7-MuiTimePickerToolbar-amPmSelection {
  //   max-width: 100px !important;
  //   display: flex !important;
  //   flex-direction: column !important;
  //   border: 1px solid black;
  // }

  .static__time_patient {
    

    & .css-ihhycx-MuiTimePickerToolbar-amPmSelection{
      margin-right: 0 !important;
    }
    & .css-88nwcr-MuiPickersToolbar-root-MuiTimePickerToolbar-root{
      display: flex !important;
      align-items: center !important;
    align-content: center !important;
    }

    & .css-r9m0hv,
    .MuiPickersToolbar-content{
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      align-content: center !important;
      width: auto !important;
    }
   & .css-jhsfre-MuiGrid-root-MuiPickersToolbar-content{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    align-content: center !important;
  }
  & .css-zddqwy-MuiClockNumber-root,
  .MuiClockNumber-root{
    font-size: x-large !important;
  }
  & .css-1mgnakj-MuiTypography-root-PrivatePickersToolbarText-root.Mui-selected{
    background-color: #DAF9F2 !important;
  }
  & .css-iyrw7v-MuiTypography-root-PrivatePickersToolbarText-root.Mui-selected, .PrivatePickersToolbarText-root.Mui-selected{
    background-color: #DAF9F2 !important;

  }
  }