button {
  border-style: none;

  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  transition: all 0.2s;

  &.btn__primary__ghost {
    color: #3FD4AF;
    background-color: transparent;
    background-image: none;
    border-color: transparent;
    border-radius: 6px;
    padding: 8px 16px;

    &:hover {
      color: #fff;
      background-color: #3FD4AF;
      border-color: #3FD4AF;
    }
  }

  &.btn__secondary__ghost {
    color: #384250;
    background-color: transparent;
    background-image: none;
    border-color: transparent;
    border-radius: 6px;
    padding: 8px 16px;

    &:hover {
      color: #fff;
      background-color: #384250;
      border-color: #384250;
    }
  }

  &.btn__primary {
    color: white;
    border-radius: 6px;
    background-color: $primary;
    padding: 8px 16px;

    &:disabled {
      background: #2e9275de;
      cursor: not-allowed;

    }

    &.cert__btn__red {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      letter-spacing: 0.01em;
      color: #ffffff;
      padding: 8px 16px;
      gap: 10px;
      max-width: 146.5px;
      min-height: 35px;
      background-color: #b42318;
      &:active {
        background-color: #912018;
      }
      &:hover {
        background-color: #f04438;
      }

    }

  }

  &.btn__primary-outline {
    padding: 8px 16px;
    border: 1px solid $primary;
    border-radius: 6px;
    background: #fff;
  }

  &.btn__primary-outline-g {
    padding: 8px 16px;
    border: 1px solid #9da4ae;
    color: #4d5761;
    border-radius: 6px;
    background: #fff;
  }

  &.btn__primary-outline-g2 {
    padding: 8px 16px;
    background: #fff;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #4d5761;
    max-width: 146.5px;
    min-height: 35px;
  }

  &:focus {
    outline: none;
  }
}

.cancel_btn {
  border: 1px solid #4d5761;
  //styleName: Text Extra Small/Medium;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #384250;

  &:hover {
    border: 1px solid #6c737f;
    color: #6c737f;
  }

  &:active {
    order: 1px solid #1f2a37;
    color: #1f2a37;
  }
}

.save_btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  background: #0bb68c;
  min-width: 131px;
  height: 35px;

  &.careteam {
    height: 40px;
  }

  &:hover {
    background: #3fd4af;
    color: #ffffff;
  }

  &:active {
    background: #21856c;
    color: #ffffff;
  }
}

.success-login-btn {
  background: $primary;
  border-radius: 8px;
  border-style: none;
  color: white;
  font-size: 18px;
  font-weight: 600;
  height: 51px;
  line-height: 26px;
  width: 100%;
  max-width: 150px;
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none !important;

  &:focus,
  &:active,
  &:hover {
    background: #3fd4af !important;
    border: 1px solid #d7fef4;
    box-shadow: none !important;
    color: white !important;
    filter: drop-shadow(0px 6.4px 14.4px rgba(0, 0, 0, 0.132))
      drop-shadow(0px 2.2px 4.6px rgba(0, 0, 0, 0.13));
  }
}

.button-add-new-user {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 8px;

  width: 125px;
  height: 40px;

  /* Brand 2.0/500 */

  background: #0bb68c;
  border-radius: 6px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: center;

  /* Base/White */

  color: #ffffff;

  &:hover {
    background: #3fd4af;
    color: #ffffff;
  }
  &:active {
    background: #21856c;
    color: #ffffff;
  }
}

button.btn__primary-outline.custom-edit-btn {
  color: #384250;
  border: 1px solid #384250;
  font-family: Inter !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 15px !important;
  letter-spacing: 0.01em !important;
  text-align: center;
  width: 96px !important;
}

button.btn__primary-outline.custom-edit-btn:hover {
  color: #6c737f;
  border: 1px solid #6c737f !important;
}
