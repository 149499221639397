/*Top Calendar Header*/

/*Global*/
.e-toolbar-item.e-today.e-overflow-show {
    display: none !important;
}

.e-toolbar-right:hover,
.e-toolbar-right .e-toolbar-item:hover,
.e-toolbar-right .e-toolbar-item button:hover {
    box-shadow: none !important;
}

.e-icons::before {
    display: inline-block;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}

    .e-toolbar-item.e-date-range{
        width: 30% !important;
    
        .e-tbar-btn{
            width: 100% !important;
        }
    }
    .e-toolbar-item.e-prev.e-tbtn-align.e-overflow-show {
        margin-right: 33%;
        position: absolute;
    }
    .e-toolbar-item.e-next.e-tbtn-align.e-overflow-show {
        position: absolute;
        margin-left: 33%;
    }

/*Caseload View*/
.caseload-view {
    .e-toolbar-items.e-tbar-pos {
        background: #FFFFFF !important;
        height: 48px !important;
    }

    .e-schedule-toolbar.e-control.e-toolbar.e-lib.e-keyboard.e-toolpop {
        box-shadow: none !important;
    }

    .e-toolbar-left {
        max-height: 24px !important;
        width: 100%;
        display: flex !important;
        flex-direction: row;
        justify-content: center;
        align-items: center;

    }

    .e-toolbar-left .e-toolbar-item {
        height: 24px !important;
    }

    .e-toolbar-item.e-date-range.e-overflow-show span.e-tbar-btn-text {
        //styleName: Text Large/Bold;
        font-family: Inter;
        font-size: 18px !important;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: center;
        text-transform: uppercase !important;

    }

    span.e-btn-icon.e-icon-down-arrow.e-icons.e-icon-right {
        display: none;
    }

  

    .e-toolbar-right {
        max-height: 24px !important;
        width: 112px;
        display: flex !important;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        border-radius: 4px;
        border: solid 1px #0BB68C;

    }


    .e-toolbar,
    .e-tbar-btn {
        background: #FFFFFF00 !important;
    }

    

    .e-toolbar-item.e-schedule-seperator.e-separator {
        display: none;
    }

    .e-toolbar-right .e-toolbar-item {
        width: 50% !important;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 24px !important;
        height: 24px !important;
        padding: 0 !important;
        margin: 0 !important;

        button {
            width: 100% !important;
            height: inherit !important;
            margin: 0 !important;

            span {
                font-family: Inter !important;
                font-size: 12px !important;
                font-weight: 400 !important;
                line-height: 15px !important;
                letter-spacing: 0em !important;
                text-align: left !important;
                color: #384250;
                text-transform: capitalize !important;
            }
        }
    }

    .e-toolbar-right .e-toolbar-item button:focus, .e-active-view button  {
        background: #0BB68C !important;
        box-shadow: none !important;

        span {
            color: #FFF !important;
        }

    }
    .e-active-view button span{
        color: #FFF !important;
    }

    /*
.e-toolbar-right .e-toolbar-item button:focus{
    border-bottom: 1px solid #0BB68C !important;
    background: #F5FFFD !important;
    box-shadow: none  !important;

    span {
        color: #21856C !important;
    }

}
*/

}



/*Schedule View*/
.schedule-view {
    .e-toolbar-items.e-tbar-pos {
        background: #FFFFFF !important;
        height: 150px !important;
    }

    .e-schedule-toolbar.e-control.e-toolbar.e-lib.e-keyboard.e-toolpop {
        box-shadow: none !important;
        margin-top: 10px;
    }

    .e-toolbar-left {
        max-height: 24px !important;
        width: 100%;
        display: flex !important;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 94px;
    }

    .e-toolbar-left .e-toolbar-item {
        height: 24px !important;
    }

    .e-toolbar-item.e-date-range.e-overflow-show span.e-tbar-btn-text {
        //styleName: Text Large/Bold;
        font-family: Inter;
        font-size: 18px !important;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: center;
        text-shadow: none !important;
        text-transform: uppercase !important;

    }

    span.e-btn-icon.e-icon-down-arrow.e-icons.e-icon-right {
        display: none;
    }

    .e-toolbar-right {
        max-height: 50px !important;
        width: 100%;
        display: flex !important;
        flex-direction: row;
        justify-content: space-around;
        align-items:
            center;
        //padding: 0 50px
    }



    .e-toolbar,
    .e-tbar-btn{
        background: #FFFFFF00 !important;
    }



    .e-toolbar-item.e-schedule-seperator.e-separator {
        display: none;
    }

    .e-toolbar-right .e-toolbar-item {
        min-width: 33.33% !important;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 50px !important;
        height: 50px !important;
        padding: 0 !important;

        button {
            width: 100% !important;
            height: inherit !important;
            margin: 0 !important;

            span {
                //styleName: Text Small/Medium;
                font-family: Inter !important;
                font-size: 14px !important;
                font-weight: 500 !important;
                line-height: 17px !important;
                letter-spacing: 0em !important;
                text-align: left !important;
                color: #6C737F;
                text-transform: capitalize !important;
            }
        }
    }

    .e-toolbar-right .e-toolbar-item button:focus, .e-active-view button, .e-active-view button:hover {
        border-bottom: 1px solid #0BB68C !important;
        background: #F5FFFD !important;
        box-shadow: none !important;

        span {
            color: #21856C !important;
        }

    }
}