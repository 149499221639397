.auth__main-container {
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  width: 100%;
  // min-height: 100vh;
  // @include devices(tablet) {
  //   display: grid;
  //   grid-template-columns: repeat(11, minmax(0, 1fr));
  // }
  // &.reverse {
  //   display: flex;
  //   flex-direction: column-reverse;
  //   @include devices(tablet) {
  //     display: grid;
  //     grid-template-columns: repeat(11, minmax(0, 1fr));
  //   }
  // }
}

.auth__white-container {
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  min-height: 100vh;
  padding: 0 100px;
  place-content: center;
  align-items: center;
  flex-grow: 1;

  &.sigin {
    padding: 0 120px;
  }
}

.auth__white-container-recovery {
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  min-height: 100vh;
  padding: 0 100px;
  place-content: center;
  align-items: center;
  flex-grow: 1;
  background: #ffffff;
}

.auth__white-container-signup {
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  min-height: 100vh;
  padding: 0 100px;
  flex-grow: 1;
}

form.login-form {
  width: 100%;
  max-width: 550px;
  margin-top: 48px;

  .italic__placeholder {
    border: 2px solid #D6D6D6 !important;
    border-radius: 8px !important;
  }

  .mycheck {
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    background: #FFFFFF;
    border: 1px solid #DBDDE0;
    border-radius: 4px;

    line-height: 10px;
    font-size: 10px;

    &:checked{
      padding: 4px;
      box-shadow: none !important;
    }
  }
}

form.sign-form {
  max-width: 386px;
  margin-bottom: 150px !important;
  margin: auto;
  //margin-left: 20%;

  label {
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #384250;
    background-color: #ffffff;
    padding: 0 2px;
    position: absolute;
    top: -8px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14.52px;
    font-family: Inter;
    border-radius: 15px;
  }

  .text__input {
    margin-bottom: 26px;
  }
}

.img__input {
  position: absolute;
  top: 16px;
  left: 16px;
  width: 16px;
  height: 16px;
  color: #6c737f;
  font-weight: 400 !important;
  font-size: 16px;
}

.img__input_show {
  position: absolute;
  margin-top: 18px;
  margin-left: -35px;
  cursor: pointer;
  color: #6c737f;
  font-weight: 400 !important;
  font-size: 16px;
}

.forgot-link {
  text-decoration: none;
  color: $primary;
  font-family: $font_primary;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center !important;
  margin-top: 28px;
  width: 100%;
  // margin-bottom: 150px;
}
.terms-condition-label{
  /* Text Small/Regular */

font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 17px;
text-align: start;

/* Gray neutral/500 */

color: #6C737F;
}

.inner-contact-label {
  //styleName: Text Small/Regular;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left !important;
  color: #6c737f;
  margin-top: -18px !important;
  padding-bottom: 18px !important;
}

.auth__form-footer {
  display: flex;
  flex-direction: column;
  margin: 25px 0px 49px 0px;
  place-content: space-between;

  @include devices(mobile) {
    flex-direction: row;
  }

  .link {
    font-weight: 400;
    font-size: 14px;
    line-height: 139.7%;
    margin-top: 24px;

    @include devices(mobile) {
      margin-top: 0;
    }
  }
}

.auth__green-container {
  background: url("../../../../public/images/GreenBg.png") no-repeat center;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  color: white;
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  min-height: 100vh;
  place-content: center;
  align-items: center;
  flex-grow: 1;
}

.green__center-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
}

.green__container-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #FFFFFF;

  display: flex;
  align-items: center;
  justify-content: center;

  //margin: 16px 0;
  @include devices(mobile) {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
  }
}

.auth__success-main-container {
  background-color: #f5f9fa;
  display: flex;
  height: 100vh;
  width: 100%;
}

.auth__success-left-container {
  background: url("../../../../public/images/success-left.png") no-repeat left;
  height: 100%;
  width: 15%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.auth__success-content-container {
  width: 70%;
  //margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.auth__success-right-container {
  background: url("../../../../public/images/success-right.png") no-repeat right;
  height: 100%;
  width: 15%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.recovery-form {
  width: 100%;

  .text__input {
    margin-top: 24px;
    max-width: 386px;

    label {
      background-color: #ffffff;
    }
  }

  button {
    max-width: 386px;
  }
}