.elipse__visit{
    width: 26px;
    height: 26px;   
    border-radius: 50%;
    border: 0.1px solid #7f8286;

    cursor: pointer;

    &.selected {
        border: 2px solid #344054;
    }

    & img {
        position: relative;
        top: 6px;
        left: 5px;
        width: 14px;
        height: 13px;
        
   
      }
}

.visit-color-modal{
    display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;
padding: 16px;

max-width: 214px;
max-height: 289px;
background: #FFFFFF;
/* Large */

box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
border-radius: 8px;
}

.hexagon-container{
    width: 182px;
    height: 162px;
}
.visit-color-end-container{
    display: flex;
    justify-content: end;
    margin-top: 20px;

    & .reset-visit-color{
        margin-right:  20px;
        width: 33px;
        height: 15px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #000000;
    }
    & .done-visit-color{
        width: 31px;
        height: 15px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #000000;
    }

}

.visit-color-picker-modal {
    z-index: 999;
    position: absolute;
    // top: 60%;
    // left: 59%;

    &.open{
        display: block;
    }
    &.closed{
        display: none;
    }
}