header.c-header {
    border-bottom: none !important;
}

.logicly-header {
    background: #F9F9F9;
    width: 100%;
    min-height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.lgly-header-logo {
    width: 25%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-left: 67px;

}

.lgly-header-menu {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-right: 48px;
}

.lgly-header-user {
    width: 25%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

}

.lgly-header-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 34px;
    width: 96px;
    border-radius: 6px;
    border: none;
    margin-right: 16px;

    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;

}

.lgly-header-btn-green {
    background: #0BB68C;
    color: #FAFAFAF2;

}

.lgly-header-btn-grey {
    background: #E8E8E8;
    color: #888888;


}

ul.lgly-header-menu-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 0px;

    li.lgly-header-menu-item {
        cursor: pointer;
        list-style: none;
        padding: 0 24px;

        a.success_link {
            font-family: Inter;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            color: $font-gray;
            text-decoration: none;
        }

        ;

        a.success_link:active,
        a.success_link:focus {
            color: #0BB68C;

        }

    }

}

//nav bar for loggedin users

.logicly-header-loggedin-main {
    width: 100%;
    margin: 0 40px;
    border-bottom: 1px solid #D2D6DB;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.header-icon-left {
    width: 10%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    svg{
        width: 24px;
        height: 24px;
        fill: #384250;
        path{
            fill: #384250;
        }
    }
}

.header-user-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 30%;

    div {
        cursor: pointer;
        margin-left: 24px;

    }

    svg{
        width: 24px;
        height: 24px;
        fill: #D2D6DB;
        path{
            fill:#D2D6DB;
        }
    }
    ;

    .header-user-pic {
        width: 40px;
        height: 40px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1.2px solid #D6D6D6;

      font-family: Inter;
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: center;
      color: #6C737F;
      margin: 0;
    }

}
