.template-visit-month *, .template-visit-week * , .template-visit-day *{
    margin: 4px 3px;
}

p.template-visit-content.visit-type {
    //styleName: Text Legal/Medium;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 10px;
  text-align: center;
  color: #384250;
margin-top: 8px !important;

}

p.template-visit-content.visit-title {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #111927;
}
p.template-visit-content.visit-title-footer{
    margin-bottom: 6px !important;
font-family: Inter;
font-size: 12px;
font-weight: 500;
line-height: 15px;
letter-spacing: 0.01em;
text-align: center;
color: #111927;
}
p.template-visit-content.visit-title-alone {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0.01em;
    text-align: center;
}

.template-visit-content.visit-footer {
    box-sizing: border-box;
    width: auto !important;
    margin-bottom: 6px !important;

    font-family: Inter;
    font-size: 8px;
    font-weight: 500;
    line-height: 10px;
    letter-spacing: 0em;
    text-align: center;
    color: #384250;

    max-height: 20px;
}
@media only screen and (min-width: 1920px) {
    .template-visit-content.visit-footer {
        display: flex;
        justify-content: center;
  }
}

.visit-compress{
    margin-top: 2px;
    margin-bottom: 2px;
}

.visit-compress-strong{
    margin-top: 0px !important;
    margin-bottom: 0px !important;

    p {
        margin-top: 0px !important;
        margin-bottom: -2px !important;
    }

    div {
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }
}
