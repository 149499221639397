.text__input {
  position: relative;
  width: 100%;

  &.user__input {

    label {
      /* Text Extra Small/Medium */

      font-family: "Inter";
      font-style: normal;
      font-weight: 900;
      font-size: 12px;
      line-height: 15px;
      /* identical to box height */

      letter-spacing: 0.01em;

      /* Gray neutral/700 */

      color: #384250;
      z-index: 999;
    }
  }

  label {
    background-color: white;
    padding: 0 2px;
    position: absolute;
    top: -8px;
    left: 20px;
    font-weight: 400;
    font-size: 12px;

    span.span-required {
      color: #d92d20 !important;
    }
  }

  .input__error {
    border-color: #ce195a !important;
  }

  input {
    border-radius: 8px;
    border-style: none;
    border: 1px solid #d2d6db;
    font-size: 14px;
    font-weight: 400;
    height: 48px;
    line-height: 20px;
    //margin-bottom: 40px;
    width: 100%;

    &.user-input {
      height: 41px;
      /* Text Small/Regular */

      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height */

      /* Gray neutral/900 */

      color: #111927;
      background: #fcfcfd;
    }
    &.input-success-border {
      border-color: #0bb68c !important;
    }
    &.isPlan::placeholder{
      color: #E5E7EB;
    }

    &.italic__placeholder {
      @extend input;

      &::placeholder {
        font-style: italic;
      }
    }
    &.italic__placeholder_forgot {
      @extend input;
      margin-top: 24px !important;

      &::placeholder {
        font-style: italic;
      }
    }
  }
}

.text__area {
  @extend .text__input;

  textarea {
    @extend input;
    padding: 10px;
    min-height: 131px;
  }
}

.select__form {
  @extend .text__input;

  select {
    border-radius: 8px;
    border-style: none;
    border: 1px solid #d2d6db;
    font-size: 16px;
    font-weight: 400;
    height: 48px;
    line-height: 20px;
    margin-bottom: 26px;
    width: 100%;

    &::placeholder {
      font-style: italic;
    }
  }
}

.img__input {
  position: absolute;
  top: 12px;
  left: 16px;
}

.checkbox__input {
  &.parking{
    label {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      align-items: center;
      
      /* Gray neutral/700 */
      
      color: #384250;
    }
  }
  label {
    align-items: center;
    color: $primary;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    gap: 8px;
    line-height: 16px;
    padding: 0px;
  }

  input {
    appearance: none;
    background: white;
    border-radius: 4px;
    border: 1px solid #dbdde0;
    cursor: pointer;
    height: 20px;
    margin-top: 0;
    width: 20px;

    &:checked {
      appearance: none;
      content: url("../../../public/images/checked.png");
      padding: 1px;
      background: #ffffff;
      border: 1px solid $primary;
      box-shadow: 0px 1px 4px #e5e9f2 !important;
      border-radius: 4px;
      //content: '\f00c';
      color: #0bb68c;
      font-family: "Font Awesome 5 Free";
      font-size: 20px;
      font-weight: 900;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
    }

    &:focus {
      outline-style: none;
    }
  }
}

.radio__input {
  padding-bottom: 20px;

  input {
    @extend .radio__btn;
    margin-right: 8px;
    outline: 1px solid #d2d6db !important;
  }

  label {
    //styleName: Text Small/Regular;
  /* Text Base/Regular */

font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;
display: flex;
align-items: center;

/* Gray neutral/700 */

color: #384250;
  }
}

.my-datepicker {
  position: relative;
  width: 100%;

  label {
    background-color: white;
    padding: 0 2px;
    position: absolute;
    top: -8px;
    left: 20px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    z-index: 1111;

    span.span-required {
      color: #d92d20 !important;
    }
  }

  .custom-datepicker,
  .custom-datepicker-edit,
  .custom-datepicker-error {
    border-radius: 8px;
    border-style: none !important;
    font-size: 14px;
    font-weight: 400;
    height: 48px;
    line-height: 20px;
    //margin-bottom: 40px;
    width: 100%;

    &::after,
    &::before {
      background: transparent !important;
    }

    input {
      padding-left: 2rem !important;
    }

    span.e-input-group-icon.e-date-icon.e-icons {
      padding: 14px;

      &:before {
        // content:"\f073";
        // font-family:'fontawesome';
        width: 16px;
        height: 16px;
        color: #6c737f;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &.certification {
      height: 44px;
      background: #ffffff;
      border: 1px solid #d2d6db;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
    }
  }

  .custom-datepicker-edit {
    border: 1px solid $primary !important;
  }
  .custom-datepicker-error {
    border: 1px solid #ce195a !important;
  }

  .custom-datepicker {
    border: 1px solid #d2d6db !important;
  }
}

/* To specify background color, color, and border */
.e-calendar .e-content td:hover span.e-day,
.e-calendar .e-content td:focus span.e-day,
.e-bigger.e-small .e-calendar .e-content td:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td:focus span.e-day {
  background-color: #0bb68c;
  border: none !important;
  color: #fff !important;
}

.e-calendar .e-btn.e-today.e-flat.e-primary,
.e-calendar .e-css.e-btn.e-today.e-flat.e-primary {
  display: none;
}

/* To specify background color and color  */
.e-calendar .e-content td.e-focused-date.e-today span.e-day,
.e-calendar .e-content td.e-focused-date.e-today span.e-day:hover {
  background-color: #0bb68c !important;
  border: none !important;
  color: #fff !important;
}

p.form-hint-text {
  //styleName: Text Small/Regular;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #6c737f;
  margin-top: 8px;
}

.custom-dropdown,
.dropdown-error-class,
.custom-dropdown-edit {
  position: relative;
  width: 100%;

  &.user__input {
    label {
      font-weight: 900;
      font-family: "Inter";
      line-height: 15px;
      z-index: 1000;
    }
  }

  label {
    background-color: white;
    padding: 0 2px;
    position: absolute;
    top: -8px;
    left: 20px;
    font-weight: 900;
    font-size: 12px;
    line-height: 20px;
    z-index: 1;

    span.span-required {
      color: #d92d20 !important;
    }
  }

  &.input__error span.e-input-group {
    border-color: #ce195a !important;
  }

  &.input-success-border span.e-input-group {
    border-color: #0bb68c !important;
  }

  span.e-input-group {
    border-radius: 8px;
    border-style: none !important;
    border: 1px solid !important;
    font-size: 14px;
    font-weight: 400;
    height: 48px;
    line-height: 20px;
    //margin-bottom: 40px;
    width: 100%;

    &::after,
    &::before {
      background: transparent !important;
    }

    input {
      padding-left: 2rem !important;
    }

    span.e-input-group-icon {
      padding: 14px;
      margin: 0;

      &:before {
        content:"\e916" !important;
        // font-family:'fontawesome';
        width: 16px;
        height: 16px;
        color: #6c737f;
        align-items: center;
        justify-content: center;
        display: flex;
      }
    }
  }
}

.input_edit {
  border-color: $primary !important;

  input,
  .flag-dropdown {
    border-color: $primary !important;
  }
}

.custom-dropdown {
  span.e-input-group {
    border-color: #d2d6db !important;
  }
}

.custom-dropdown-edit {
  span.e-input-group {
    border-color: $primary !important;
  }
}

.dropdown-error-class {
  span.e-input-group {
    border-width: 1px;
    border-style: solid;
    border-color: #ce195a !important;
  }
}

.e-dropdownbase .e-list-item,
.e-dropdownbase .e-list-item.e-item-focus {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #111927;
  min-height: 39px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.e-dropdownbase .e-list-item.e-item-focus,
.e-dropdownbase .e-list-item.e-active,
.e-dropdownbase .e-list-item.e-active.e-hover,
.e-dropdownbase .e-list-item.e-hover {
  background-color: #f9fafb;
  color: #111927 !important;
}

.e-dropdownbase .e-list-item.e-active:after {
  content: "\f00c";
  color: #0bb68c;
  font-family: "Font Awesome 5 Free";
  font-size: 20px;
  font-weight: 900;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
}

.custom-phone.react-tel-input {
  position: relative;
  width: 100% !important;
  border-radius: 8px 0 0 8px;

  .special-label {
    display: block !important;
    background-color: white;
    padding: 0 2px;
    position: absolute;
    top: -8px;
    left: 20px;
    font-weight: 900;
    font-size: 12px;
    line-height: 20px;
  }

  .input__error {
    border-color: #ce195a !important;
  }

  .flag-dropdown {
    background: #fff !important;
    border-radius: 8px 0 0 8px;
    border-style: none;
    border: 1px solid #d2d6db;
  }

  input {
    border-radius: 8px;
    border-style: none;
    border: 1px solid #d2d6db;
    font-size: 14px;
    font-weight: 400;
    height: 48px;
    line-height: 20px;
    //margin-bottom: 40px;
    width: 100%;

    &::placeholder {
      font-style: italic;
      margin-bottom: 24px;
    }
  }
}

.error-class {
  .flag-dropdown {
    border-color: #ce195a !important;
  }
}

.e-dropdownbase {
  ::-webkit-scrollbar {
    width: 8px !important;
  }

  ::-webkit-scrollbar-thumb {
    background: #e5e7eb !important;
  }

  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    background-clip: padding-box;
  }
}

.label-radio {
  //styleName: Text Base/Regular;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #384250;
}

.input-without-border {
  border: 0 !important;
  font-size: 14px;
  font-weight: 400;
  height: 48px;
  line-height: 20px;
  width: 100%;
  background: white;
}

.select__form__withoutborder {
  @extend .text__input;

  select {
    cursor: default;
    pointer-events: none;
    -moz-appearance: none;
    text-indent: 0.01px;

    background: white;
    -webkit-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    border: 0;
    font-size: 16px;
    font-weight: 400;
    height: 48px;
    line-height: 20px;
    margin-bottom: 26px;
    width: 100%;

    &::placeholder {
      font-style: italic;
    }
  }
}
.user-icon-clock-position {
  position: absolute;
  top: 12px;
  left: 13px;
  width: 16px;
  height: 16px;

  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;

  /* Gray neutral/400 */

  color: #9da4ae;
}
.user-icon-position {
  position: absolute;
  top: 12px;
  left: 13px;
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;

  /* Gray neutral/500 */

  color: #6c737f;
}

.user-icon-lock-position {
  position: absolute;
  top: 11.5px;
  left: 13px;
  width: 14px;
  height: 16px;

  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;

  /* Gray neutral/500 */

  color: #6c737f;
}
.user-icon-position-show {
  position: absolute;
  top: 13.5px;
  margin-left: -35px;
  cursor: pointer;
  color: #d2d6db;
  font-weight: 900;
  width: 16px;
  height: 16px;
}
.user-eye-icon {
  width: 18px;
  height: 16px;

  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 900 !important;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height */

  /* Gray neutral/500 */

  color: #6c737f;
}
.custom-dropdown-user,
.dropdown-error-class {
  position: relative;
  width: 100%;

  &.user__input {
    label {
      /* Text Extra Small/Medium */

      font-family: "Inter";
      font-style: normal;
      font-weight: 900;
      font-size: 12px;
      line-height: 15px;
      /* identical to box height */

      letter-spacing: 0.01em;

      /* Gray neutral/700 */

      color: #384250;
      z-index: 999;
    }
  }

  label {
    background-color: white;
    padding: 0 2px;
    position: absolute;
    top: -8px;
    left: 20px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    z-index: 1111;

    span.span-required {
      color: #d92d20 !important;
    }
  }
  //To Show Error in DropDown
  &.input__error span.e-input-group {
    border-color: #ce195a !important;
  }

  &.input-success-border span.e-input-group {
    border-color: #0bb68c !important;
  }
  span.e-input-group {
    border-radius: 8px;
    border-style: none !important;
    //border: 1px solid !important;
    height: 41px;
    display: flex;
    align-items: center;
    /* Text Small/Regular */

    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    /* Gray neutral/900 */

    color: #111927;

    &::after,
    &::before {
      background: transparent !important;
    }

    input {
      padding-left: 2rem !important;
    }

    span.e-input-group-icon {
      padding: 14px;
      margin: 0;

      &:before {
        // content:"\f073";
        // font-family:'fontawesome';
        width: 16px;
        height: 16px;
        color: #6c737f;
        align-items: center;
        justify-content: center;
        display: flex;
      }
    }
  }
}

.custom-dropdown-user {
  span.e-input-group {
    border-color: #d2d6db !important;
  }
}
.dropdown-error-class {
  border-width: 1px;
  border-color: #ce195a !important;
  border-radius: 8px;
}
.custom-dropdown-careteam,
.dropdown-error-class {
  position: relative;
  width: 100%;

  &.careteam__input {
    label {
      font-weight: 500;
      font-family: "Inter";
      line-height: 15px;
      z-index: 1000;
    }
  }

  label {
    background-color: white;
    padding: 0 2px;
    position: absolute;
    top: -8px;
    left: 20px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    z-index: 1111;

    span.span-required {
      color: #d92d20 !important;
    }
  }
  //To Show Error in DropDown
  &.input__error span.e-input-group {
    border-color: #ce195a !important;
  }

  &.input-success-border span.e-input-group {
    border-color: #0bb68c !important;
  }
  span.e-input-group {
    border-radius: 8px;
    border-style: none !important;
    // border: 1px solid !important;
    font-size: 14px;
    font-weight: 400;
    height: 44px;
    line-height: 20px;
    //margin-bottom: 40px;
    width: 100%;

    &::after,
    &::before {
      background: transparent !important;
    }

    input {
      padding-left: 2rem !important;
    }

    span.e-input-group-icon {
      padding: 14px;
      margin: 0;

      &:before {
        // content:"\f073";
        // font-family:'fontawesome';
        width: 16px;
        height: 16px;
        color: #6c737f;
        align-items: center;
        justify-content: center;
        display: flex;
      }
    }
  }
}

.custom-dropdown-careteam {
  span.e-input-group {
    border-color: #d2d6db !important;
  }
}
.span-absolute,
.span-absolute-large {
  background-color: white;
  padding: 0 2px;
  position: absolute;
  z-index: 11;
  margin-top: -7px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #d92d20 !important;
}
.span-absolute {
  margin-left: 144px;
}
.span-absolute-large {
  margin-left: 160px;
}
