.c-sidebar,
.c-sidebar-brand,
.c-sidebar-nav {
    background: #fff !important;

}

.c-sidebar {
    height: 100vh;
    width: 256px;
    left: 0px !important;
    top: 0px;
    border-radius: 0px;
    padding: 40px 16px 40px 16px;
    margin-left: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .ico {
        float: left;
        width: 20px;
        height: 20px;
        margin-right: 17px;
    }

    .ico-text {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        height: 20px;
    }

    .logo img {
        margin: 8px 0 0 0;
        width: 80%;
        height: auto;
    }

    .logo span {
        display: none;
    }
}

.c-sidebar-nav {
    margin-top: 32px;
    width: 100%;
    gap: 8px;
}

ul.c-sidebar-nav li {
    cursor: pointer;
    min-height: 54px;
    border-radius: 12px;
}

.c-sidebar-nav li a {
    font-family: Inter;
    font-size: 15px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    // text-align: left;
    text-decoration: none;
    // display: block;
    color: #0BB68C;
    padding: 16.5px 16px;
    height: 54px;

    display: flex;
    flex-direction: row;
    align-content: center;

    &:hover {
        border-radius: 12px;
        background: #3FD4AF;
        color: #FFFFFF;
        height: 54px;
    }

    &:active {
        border-radius: 12px;
        background: #21856C;
        color: #FFFFFF;
        height: 54px;
    }
}

.c-sidebar-nav li .inactive {
    font-family: Inter;
    font-size: 15px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    text-decoration: none;
    // display: block;
    color: #9DA4AE;
    padding: 16.5px 16px;
    cursor: auto;

    display: flex;
    flex-direction: row;
    align-content: center;
}

ul.c-sidebar-nav li.active {
    background: #0BB68C;

    a {
        color: #fff;
    }
}

ul.c-sidebar-nav li i {
    margin: 0 16px 0 0px;
}

.ps__rail-x {
    display: none !important;
}

.ps__rail-y {
    display: none !important;
}