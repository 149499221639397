.pagination__value {
  align-items: center;
  border-radius: 8px;
  color: #484f5a;
  font-size: 14px;
  font-weight: 400;
  height: 37px;
  justify-content: center;
  line-height: 21px;
  margin: 0 4px;
  padding: 8px 16px;
  width: 37px;

  &:hover{
    background: #3FD4AF;
    color: #FFFFFF;
  }
  &:active{
    background: #21856C;
    color: #FFFFFF;
  }

  &.current {
    background: #0BB68C;
    color: white;
  }

  &.spread:hover {
    background-color: transparent !important;
    color: #484f5a !important;
  }
}

.page-list-size,
.patients-select__form {
  //styleName: Text Small/Regular;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
  background: #ffff;
}

.page-list-size {
  color: #3F3F46;
}

.patients-select__form {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #4D5761;
  height: 29px;
  width: 57px;
  border-radius: 4px;
  padding: 6px 9px 6px 12px;
  height: 29px;
  width: 57px;
  padding-left: 6%;
  border: 1px solid #D2D6DB;

  &::-ms-expand {
    display: none;
  }

}

.arrow-light,
.arrow-dark {
  width: 20px;
  height: 20px;
}

.arrow-light {
  color: #D2D6DB;

  path {
    fill: #D2D6DB;
  }
}

.arrow-dark {
  color: #1F2A37;

  path {
    fill: #1F2A37;
  }
}

.pag-btn-light {
  color: #D2D6DB;
}

.pag-btn-dark {
  color: #1F2A37;
}

.pagination-dropdown {
  .e-input-group {
    border: 1px solid #D2D6DB !important;
    border-radius: 4px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 9px 6px 12px;
    height: 29px;
    margin: 0px !important;

    input {
      color: #4D5761 !important;
      font-family: 'Inter' !important;
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 17px !important;
      width: 25px !important;
    }

    .e-input-group-icon {
      margin: 0px;
      width: 12px;
      height: 12px;

      &::before {
        margin-left: 6px;
        content: '\f107' !important;
        font-family: 'Font Awesome 5 Free' !important;
        font-style: normal;
        font-weight: 900;
        font-size: 12px;
        line-height: 12px;
        color: #384250;
        text-align: center;
        width: 12px;
        height: 12px;
      }
    }

    &.e-input-focus::before {
      display: none;
    }

    &.e-input-focus::after {
      display: none;
    }
  }
}

.e-popup {
  border-radius: 8px;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.3), 0px 2px 4px -2px rgba(16, 24, 40, 0.06) !important;
}

.e-dropdownbase {
  border-radius: 9px;

  .e-list-parent {
    &.e-ul {
      height: auto !important;
    }
  }
}