.template-visit-month {
  border-bottom: solid 4px rgb(197, 115, 115);
}

.template-visit-week {
  background: #fff !important;
  border-radius: 4px !important;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
}
.template-visit-day {
  background: #fff !important;
  border-radius: 4px !important;
  box-shadow: 0px 2px 4px -2px #1018280f, 0px 4px 8px -2px #1018281a;
}

.visit-evaluation-border {
  border-bottom: solid 4px #c299ff !important;
  //border-left: solid 1px #221e1e24 !important;
  //border-right: solid 1px #221e1e24 !important;
}

.visit-admission-border {
  border-bottom: solid 4px #8cd5ff !important;
  //border-left: solid 1px #221e1e24 !important;
 // border-right: solid 1px #221e1e24 !important;
}

.visit-routinevisit-border {
  border-bottom: solid 4px #3db68c !important;
  //border-left: solid 1px #221e1e24 !important;
  //border-right: solid 1px #221e1e24 !important;
}

.visit-roc-border {
  border-bottom: solid 4px #fb5ac8 !important;
  //border-left: solid 1px #221e1e24 !important;
  //border-right: solid 1px #221e1e24 !important;
}

.visit-recert-border {
  border-bottom: solid 4px #ec6b66 !important;
  //border-left: solid 1px #221e1e24 !important;
  //border-right: solid 1px #221e1e24 !important;
}

.visit-30dre-border {
  border-bottom: solid 4px #439cba !important;
  //border-left: solid 1px #221e1e24 !important;
  //border-right: solid 1px #221e1e24 !important;
}

.visit-discharge-border {
  border-bottom: solid 4px #ffd15e !important;
  //border-left: solid 1px #221e1e24 !important;
  //border-right: solid 1px #221e1e24 !important;
}

.visit-missed-border {
  border-bottom: solid 4px #7a7a7a !important;
  //border-left: solid 1px #221e1e24 !important;
  //border-right: solid 1px #221e1e24 !important;
}
.visit-supervisor-border {
  border-bottom: solid 4px #5FE9D0 !important;
  border-left: solid 1px #221e1e24 !important;
  border-right: solid 1px #221e1e24 !important;
}
.visit-roce-border {
  border-bottom: solid 4px #C299FF !important;
  border-left: solid 1px #221e1e24 !important;
  border-right: solid 1px #221e1e24 !important;
}
