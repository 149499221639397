.patient-tabs-bar {
    width: 100%;
    height: 52px;
    border-bottom: solid 1px #E5E7EB;
}
.visit-tabs-bar {
    width: 294px;
    height: 52px;
    border-bottom: solid 1px #E5E7EB;
}
button.patient-tab-btn {
    height: 52px;
    width: 192px;
    left: 0px;
    top: 0px;
    padding: 16px 12px 16px 12px;
    border-bottom: 2px solid #F3F4F6;
    background: #FCFCFD;
    color: #6C737F;
    margin-bottom: 1px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;

    &.first{
        border-radius: 10px 0 0 0;

    }
    &.all{
        border-radius: 10px 10px 0 0;

    }
    &.second{
        border-radius: 0 10px 0 0;
    }


    &.active{
        background: #F5FFFD !important;
        border-bottom: 3px solid #0BB68C;
        color: #21856C !important;
    }

    &:hover{
        background: #D7FEF4 !important;
        border-bottom: 3px solid #0BB68C;
        color: #21856C !important;
    }

    &:active{
        background: #BBFBEB !important;
        border-bottom: 3px solid #0BB68C;
        color: #21856C !important;
    }
}
button.patient-tab-btn-desactive {
    height: 52px;
    width: 192px;
    left: 0px;
    top: 0px;
    padding: 16px 12px 16px 12px;
    border-bottom: 2px solid #F3F4F6;
    background: #FCFCFD;
    color: #6C737F;
    margin-bottom: 1px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    cursor: default;

    &.first{
        border-radius: 10px 0 0 0;

    }
    &.all{
        border-radius: 10px 10px 0 0;

    }
    &.second{
        border-radius: 0 10px 0 0;
    }

    }
button.patient-tab-btn-action {
    height: 31px;
    width: auto;
    border-radius: 4px;
    padding: 8px;

    //styleName: Text Extra Small/Medium;
    font-family: Inter;
    font-size: 12px !important;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0.01em;
    text-align: center;

    margin-left: 12px
}

button.patient-tab-btn-action.tab-btn-edit {
    border: 1px solid #4D5761;
    color: #4D5761
}

button.patient-tab-btn-action.tab-btn-delete {
    border: 1px solid #B42318;
    color: #B42318;
}