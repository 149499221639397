// .e-treeview {

// }
.e-list-parent {
    &.e-ul {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
        gap: 2px;
        margin: 0;

        max-width: none !important;
        width: inherit;

        height: calc(100vh - 335px);
        // height: 517px;
        overflow-y: auto;
        overflow-x: hidden !important;

        .e-list-item {
            width: 100%;
        }

        .e-list-text {
            width: 100%;
        }

        .e-text-content {
            padding: 0px;
        }

        .e-text-content:hover {
            background: #dde0e2;
            //background: #FFFFFF00 !important;
            height: 46px;
            border-radius: 6px;
        }

        .e-sibling {
            display: none;
        }

    }
}

.pre-visit-box {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;

    height: 34px;
    font-family: 'Inter';
    // width: 100%;

    /* text selecton */
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */

    .circle-t {
        border-radius: 15px;
        height: 16px;
        min-width: 16px;
    }

    .group-title {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #1F2A37;
        align-self: stretch;
        cursor: auto;
    }

    .info-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0px;

        .title {
            height: 15px;

            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.03em;

            color: #111927;

        }

        .description {
            height: 15px;

            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;

            word-break: break-all;

            color: #9DA4AE;
        }
    }
}

.e-drag-item {
    &.e-treeview {
        background: #FFFFFF;
        box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.1), 0px 6.4px 14.4px rgba(0, 0, 0, 0.13);
        border-radius: 10px;
        height: 50px;
        padding: 4px;

        .e-icons {
            display: none;
        }

        .e-text-content {
            padding: 0px;
        }

        .info-container {
            gap: 0px;
        }
    }
}

.mybs-select {
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
}

.visit-nomonth {
    color: #9DA4AE;
    background: #FFFFFF;
}

.visit-evaluation {
    // background: #C299FF;
    color: #1F2A37;
}
.visit-roce {
    // background: #C299FF;
    color: #1F2A37;
}

.visit-admission {
    // background: #8CD5FF;
    color: #1F2A37;
}

.visit-routinevisit {
    // background: #3DB68C;
    color: #ffff;
}

.visit-roc {
    // background: #FB5AC8;
    color: #1F2A37;
}

.visit-recert {
    // background: #EC6B66;
    color: #ffff;
}

.visit-30dre {
    // background: #439CBA;
    color: #ffff;
}

.visit-discharge {
    // background: #FFD15E;
    color: #1F2A37;
}
.visit-supervisor {
    // background: #5FE9D0;
    color: #1F2A37;
}

.visit-missed {
    // background: #7A7A7A;
    color: #1F2A37;
}

.e-treeview .e-fullrow {
    display: none;
}

.visits-adjust {
    // height: calc(100vh - 82px);
    height: 770px;
}

.visits-adjust-list {
    // height: calc(100vh - 335px) !important;
    // height: 517px !important;
    margin-left: 24px;
    padding-left: 0px;
}

.visit-projected-range {
    width: 46px;
    height: 38px;
    border-radius: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #D1E9FF;
    color: #1F2A37;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    &.initial {
        border-bottom-left-radius: 9px;
        border-top-left-radius: 9px;
    }

    &.final {
        border-bottom-right-radius: 9px;
        border-top-right-radius: 9px;
    }
    &.left-restrinct{
        border-left-width: 12px;
        border-left-color: #FFFFFF;
    }
    &.right-restrinct{
        border-right-width: 12px;
        border-right-color: #FFFFFF;
    }

    &.recert-range{
        // background: #FDA29B;
    }
}

.recert-range-ini{
    // background: #FDA29B;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 9px;
    border-top-left-radius: 9px;
    width: 46px;
    height: 38px;

    &.end-recert-range{
        border-bottom-right-radius: 9px;
        border-top-right-radius: 9px;
    }
}

.visit-projected-addmission,.visit-projected-evaluation,.visit-projected-discharged {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    left: 4px;

    // background: #8CD5FF;
    border-radius: 9px;
    width: 38px;
    height: 38px;
}

.visit-projected-30dre {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    left: 4px;

    // background: #439CBA;
    color: #FFFFFF;
    border-radius: 9px;
    width: 38px;
    height: 38px;

    &.recert-range-ini
    {
        // background: #FD6F8E;  
    }
}