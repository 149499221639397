.auth__title {
  //styleName: Text primary;
  font-family: "Inter";
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #7c7c8e;
  margin-top: 72px;
}

.auth__title_recovery {
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  color: #111927;
  margin-top: 72.5px;
  margin-bottom: 18px;
  width: 100%;
}

.sign-up-logo-container {
  max-width: 368px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.auth__title_signup {
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  margin-top: 72px;
  color: #111927;
  /* margin-left: 20%; */
  max-width: 368px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.auth__logo {
  width: 214px;
  //margin-top: 80px;
}
.auth__logo_recovery {
  width: 100%;
}
.error_color{
  color: red !important;

}
.forgot__password-paragraph {
  align-items: center;
  color: #868b92;
  display: flex;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  margin: 35px 0px;
}

.success_header_primary {
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
  color: #0bb68c;
  margin-top: 32px;
}

.success_simple_text {
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  color: #868b92;
  margin-top: 40px;
  max-width: 566px;
  &.verified-not {
    color: #d92d20;
  }
}

.success_simple_text_verification {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #4d5761;
  margin-top: 8px;
  margin-bottom: 0px;
  max-width: 566px;

  &.verifyng {
    margin-top: 40px;
  }

  &.verified-not {
    color: #d92d20;
  }
}

.success_simple_text_verification_forgot {
  width: 122px;
  height: 17px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #0bb68c;
}

.success_simple_text_verification_loging {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 8px;
  width: 85px;
  height: 35px;
  background: #0bb68c;
  border-radius: 6px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}

.success_header_primary_verification {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #0bb68c;
  margin-top: 64px;
  margin-bottom: 0px;
}

.success_dark_text {
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  color: #868b92;
  margin-top: 24px;
}

.success_link {
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  color: #0bb68c;
  text-decoration-line: underline;
  margin-top: 8px;
}

.patient__heading {
  //styleName: Text Extra Large/Medium;
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #1f2a37;
}

.patient__heading_subt {
  //styleName: Text Extra Large/Medium;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #384250;
}
.patient-note-head{
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 17px;
color: #6C737F;
}
.patient-admitting-clinician-title {
  font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;
display: flex;
align-items: center;
/* Gray neutral/700 */
color: #384250;
}

.careteam__heading {
  height: 24px;

  /* Text Extra Large/Medium */

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;

  /* Gray neutral/800 */

  color: #1f2a37;
}
