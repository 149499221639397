// If you want to override variables do it here
@tailwind base;
@tailwind components;
@tailwind utilities;

@import "variables";

@import "@coreui/coreui/scss/coreui";

@import "components/VisitDetail";
@import "components/InputForm";
@import "components/buttons";

@import "components/auth/Buttons";
@import "components/auth/Containers";
@import "components/auth/Titles";

@import "components/patients/toolbar";
@import "components/patients/hrCustom";
@import "components/patients/table";
@import "components/patients/details";
@import "components/patients/pagination";
@import "components/patients/visitHistory";
@import "components/patients/certifications";
@import "components/patients/tabs";
@import "components/patients/patient_success_error_modal";
@import "components/patients/patient_bulk_upload";
@import "components/patients/dropdown_careteam";
@import "components/patients/date_picker_patient";
@import "components/patients/dropdown_multiple";
@import "components/patients/picker_scroll";

@import "components/user/green_user_layout";
@import "components/user/user_avatar";
@import "components/user/user_error_container";
@import "components/user/user_error_modal";
@import "components/user/dropdown_user";
@import "components/user/elipse_visit";

@import "containers/Sidebar";
@import "containers/Navbar";

@import "custom";
@import "components/schedule/schedule.css";
@import "components/schedule/visitDetails.scss";
@import "components/schedule/autoschedule.scss";
@import "components/schedule/scheduleVisits.scss";

@import "components/calendar/Calendar";
@import "components/calendar/headerCalendar";
@import "components/calendar/visitPropertiesCommon";
@import "components/calendar/visitCard";

@font-face {
  font-family: "Inter";
  src: url("../../public/fonts/Inter/Inter.ttf");
}
