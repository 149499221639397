.modal-user-error {
  position: fixed;
  z-index: 100000;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}
.modal-user-upload {
  position: fixed;
  z-index: 1000;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}
.modal-main-user-upload-avatar {
  position: fixed;
  background-color: #fffbfa;
  width: 30% !important;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  z-index: 1200;

  & .modal-footer {
    border: none !important;
  }

  & div {
    margin-top: 20px;

    & canvas {
      width: 275px !important;
      height: 275px !important;
      cursor: grab !important;
      touch-action: none !important;
    }
  }
}
.modal-main-user-error {
  position: fixed;
  background-color: #fffbfa;

  width: 365px;
  height: 242px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  border: 1px solid #f04438;
  z-index: 1200;
}
