/*MONTH VIEW*/

.e-control.e-schedule.e-lib.e-keyboard {
    border: none !important;
    // height: 700px !important;
}

.e-table-wrap.e-month-view {


    div.e-table-container > div > table > tbody > tr:nth-child(1) > td > div > div > table > tbody > tr > td:nth-child(1){
        background-color: red !important;
    }

    & #root > div > div.c-wrapper > div > div > div.col-md-9 > div > div > div.e-table-container > div > table > tbody > tr:nth-child(1) > td > div > div > table > tbody > tr > td:nth-child(1){
        background-color: red !important;

    }

    /*table header*/
    table.e-schedule-table td {
        width: auto !important;
        // height: 56px !important;
    }

    // @media (max-width:1296px){
    //     table.e-schedule-table td {
    //         width: auto !important;
    //         height: auto !important;
    //     }
    // }

    td.e-header-cells,
    td.e-work-cells {
        border-color: #D0D5DD !important;

        // div {
        //     width: 100%;
        //     height: 100%;
        // }
    }
    .e-date-header-wrap > table.e-schedule-table>tbody>tr>td:nth-child(1)::before {
        content: "Sun";
        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: center;
        color: #9DA4AE;
    }
    .e-date-header-wrap > table.e-schedule-table>tbody>tr>td:nth-child(2)::before {
        content: "Mon";
        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: center;
        color: #9DA4AE;
    }
    .e-date-header-wrap > table.e-schedule-table>tbody>tr>td:nth-child(3)::before {
        content: "Tue";
        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: center;
        color: #9DA4AE;
    }
    .e-date-header-wrap > table.e-schedule-table>tbody>tr>td:nth-child(4)::before {
        content: "Wed";
        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: center;
        color: #9DA4AE;
    }
    .e-date-header-wrap > table.e-schedule-table>tbody>tr>td:nth-child(5)::before {
        content: "Thu";
        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: center;
        color: #9DA4AE;
    }
    .e-date-header-wrap > table.e-schedule-table>tbody>tr>td:nth-child(6)::before {
        content: "Fri";
        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: center;
        color: #9DA4AE;
    }
    .e-date-header-wrap > table.e-schedule-table>tbody>tr>td:nth-child(7)::before {
        content: "Sat";
        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: center;
        color: #9DA4AE;
    }



    table.e-schedule-table td span {
        position: absolute;
        z-index: -999;
    }

    td.e-header-cells {
        text-align: center !important;
        margin: 0 !important;
        padding: 0 !important;
    }

    /*calendar*/
    table.e-schedule-table colgroup col {
        width: 100px !important;
        height: 100px !important;
    }

    table.e-schedule-table.e-content-table {
        //height: 570px;
    }

    table.e-schedule-table.e-content-table tr {
        min-height: 100px !important;
    }

    table.e-schedule-table.e-outer-table td {
        background-color: #FFFFFF !important;

        & .e-work-days.e-current-date{
            background-color: #DAF9F2 !important;
        }
    }

    .e-date-header.e-navigate {
        font-family: Inter;
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: center;
        color: #D2D6DB;
        margin: 0 !important;
        margin-left: auto !important;
        padding: 8px;
        text-decoration: none;
    }

    // td.e-work-cells {
    //     overflow: auto;
    // }


    /*Appoinment details*/
    // .e-appointment-wrapper {
    //     position: relative !important
    // }

    .e-appointment-wrapper {
        padding: 0 2px;
        box-sizing: border-box;
        position: absolute !important;
        //position: relative !important;
        max-height: 78%;
        overflow: auto;
    }

    .e-appointment-wrapper::-webkit-scrollbar {
        display: none;
    }

    td.e-work-cells {
        height: 180px !important;
        position: relative;
        overflow: hidden;
    }

    .e-appointment {
        box-shadow: 4px 2px 16px -2px #1018280f, 4px 4px 22px -2px #1018281a;
        border: none !important;
        background: #FFFFFF !important;
        //width: 84px !important;
        display: flex !important;
        flex-direction: row !important;
        margin-bottom: 8px;
        position: relative !important;
        height: auto !important;
        border-radius: 4px;

        // width: auto !important;

    }

    .e-appointment-details {
        color: #111927;
        background: none !important;

        div {
            width: 100%;
        }

    }

    .e-current-day {
        background: red !important;
    }
}

.e-table-container {
    overflow-y: auto;
    height: 100%;
}

.e-content-wrap {
    height: 100%;
}

.caseload-view .e-content-wrap::-webkit-scrollbar,
.schedule-view .e-content-wrap::-webkit-scrollbar {
    display: none;
}

/*WEEK VIEW*/
.e-table-wrap.e-week-view {
    // .e-appointment-wrapper {
    //     width: 100%;
    //     padding: 0 2px;
    //     margin: 10px 0;
    //     //height: inherit;
    //     max-height: 95%;
    //     position: absolute !important;
    //     overflow: auto;
    //     top:0;
    //     //new
    //     display: grid;
    //     grid-template-columns: 1fr 1fr;
    //     grid-column-gap: 5px;
    //     grid-row-gap: 3px;
    // }
    // .e-appointment-wrapper::-webkit-scrollbar {
    //     display: none;
    // }
    // td.e-day-wrapper {
    //     height: 160px !important;
    //     position: relative;
    //     overflow: hidden;
    // }

    // .e-appointment-details .visit-compress-strong{
    //     min-height:30px;
    // }

    // .e-appointment {
    //     background: none !important;
    //     border: none !important;
    //     width: 100% !important;
    //     height: fit-content !important;
    //     position: relative !important;
    //     //top: 0 !important;
    //     //left: 0 !important;
    //     margin-bottom: 8px;


    //     //margin-left: 4px;

    //     .e-appointment-details {
    //         padding: 2px !important;
    //         //styleName: Text Extra Small/Medium;
    //         font-family: Inter;
    //         font-size: 12px;
    //         font-weight: 500;
    //         line-height: 15px;
    //         letter-spacing: 0.01em;
    //         text-align: center;
    //         color: #111927;
    //         background: none !important;
    //         //height: 100%;

    //         div {
    //             height: calc(100% - 2px);
    //         }
    //     }

    //     .e-appointment-details>div>div {
    //         height: calc(100% + 2px);
    //     }

    //     .visit-resize {
    //         // visibility: hidden;
    //         display: none;
    //     }

    //     .visit-resize-oposite {
    //         height: inherit;
    //     }

    // }

    // .e-resize-clone {
    //     .visit-resize {
    //         // visibility: visible;
    //         display: flex;
    //     }

    //     .visit-resize-oposite {
    //         display: none;
    //     }

    //     .template-visit-week * {
    //         overflow: hidden;
    //     }
    // }

    .e-appointment-details,
    .e-appointment-details>div,
    .e-appointment-details .visit-compress-strong {
        height: 100% !important;
        padding: 0 !important;
        margin-left: 3.5%
    }

    .e-appointment {
        border: none !important;
        background: transparent !important;
        padding: 4px 0 0 0 !important;
        z-index: 1;
        // box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 4px 8px -2px rgba(16, 24, 40, 0.06) !important;

    }

    .e-appointment-details {
        border: none !important;
        border-color: transparent !important;
        //box-shadow: 0px 2px 4px -2px #1018280F, 0px 4px 8px -2px #1018281A;
        color: #111927;
    }

    .e-appointment-details>div>div {
        .visit-resize {
            // visibility: hidden;
            display: none;
            color: #111927;
        }

        .visit-resize-oposite {
            height: inherit;
            color: #111927;
        }
    }

    .e-resize-clone {
        .visit-resize {
            // visibility: visible;
            display: flex;
            color: #111927;
        }

        .visit-resize-oposite {
            display: none;
            color: #111927;
        }
    }

    .e-event-resize.e-top-handler {
        top: 2px
    }

    .e-event-resize.e-bottom-handler {
        bottom: 2px;
    }

    table.e-schedule-table tr:nth-child(4n+1) td.e-work-cells {
        border-top: solid 1px #D0D5DD !important
    }

    table.e-schedule-table tr td:first-child {
        border-left: none !important;
    }

    table.e-schedule-table tr td {
        border-top: none !important;
        border-bottom: none !important;
        border-right: none !important;
    }

    /**/
    table.e-schedule-table {

        .e-header-date.e-navigate,
        .e-header-day {
            text-align: center !important;
            width: 100%;
            //styleName: Text Base/Medium;
            font-family: Inter;
            font-size: 16px !important;
            line-height: 19px;
            letter-spacing: 0em;
            text-align: center;


        }

        .e-header-day {
            font-weight: 500;
            color: #9DA4AE;
        }

        .e-header-date.e-navigate {
            font-weight: 600;
            color: #21856C;
            margin-top: 4px;
            text-decoration: none !important;

        }

        td.e-left-indent,
        .e-time-cells-wrap {
            //max-width: 50px;

            td {
                // border: none !important;

            }

            td.e-time-slots {
                //styleName: Text Small/Medium;
                font-family: Inter;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0em;
                text-align: center;
                color: #9DA4AE;
                height: 25px;

            }

        }


        table.e-schedule-table.e-content-table {


            tr {
                td {
                    //border: none !important;
                    background-color: #FFFFFF;
                    // border: solid 1px #D0D5DD;
                    height: 25px;
                }
            }

        }


    }

    //new borders

    table.e-schedule-table.e-content-table thead td {
        height: 0px !important
    }

    //
    .e-work-cells.cell-border-dashed:before {
        content: "";
        position: absolute;
        background: transparent;
        width: 100%;
        left: 0;
        top: 0;
        border: 2px dashed #D0D5DD !important;
        height: inherit;
        z-index: 11111;

    }

    .e-work-cells.cell-border-dashed {
        position: relative;
        cursor: pointer !important;
    }

    table.e-schedule-table .e-time-cells-wrap tr {
        display: none;

    }

    table.e-schedule-table .e-time-cells-wrap tr:nth-child(4n+1) {
        display: flex !important;
        height: 100px;
        display: flex;
        align-items: center
    }
}

/* DAY VIEW */

.e-table-wrap.e-day-view {

    .e-header-date.e-navigate,
    .e-header-day {
        text-align: center !important;
        width: 100%;
        font-family: Inter;
        font-size: 16px !important;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: center;


    }

    .e-header-day {
        font-weight: 500;
        color: #9DA4AE;
    }

    .e-header-date.e-navigate {
        font-weight: 600;
        color: #21856C;
        margin-top: 4px;
        text-decoration: none !important;
        display: none;

    }

    td.e-header-cells.e-disable-dates {
        border: none !important;
    }

    .e-time-slots {
        border: none !important;
        height: 100px !important;
    }

    td.e-all-day-cells.e-disable-dates {
        border: none !important;
    }


    td.e-work-cells {
        background-color: #FFFFFF !important;
        height: 100px;
    }

    td.e-time-slots span {
        font-family: Inter;
        font-size: 14px !important;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        color: #9DA4AE !important;

    }

    .e-appointment {
        background: none !important;
        border: none !important;
        // border-radius: 4px !important;
        // box-shadow: 0px 2px 4px -2px #1018280F, 0px 4px 8px -2px #1018281A !important;

        margin-left: 4px;
        //margin-top: 4px;
        max-width: 213px;
        //height: fit-content !important;


        div {
            text-align: center;
        }

        .e-appointment-details {
            //padding: 8px 2px !important;
            //styleName: Text Extra Small/Medium;
            font-family: Inter;
            font-size: 12px;
            font-weight: 500;
            line-height: 15px;
            letter-spacing: 0.01em;
            text-align: center;
            color: #111927;
            background: none !important;
            height: 100%;


        }

        .e-appointment-details>div {
            height: 100%;

            .template-visit-day {
                justify-content: space-between;
                display: flex;
                flex-direction: column;
            }
        }

        .e-appointment-details>div>div {
            height: calc(100% + 2px);
        }

    }

}

/*GLOBAL*/
.e-schedule .e-vertical-view .e-alternate-cells {
    border-bottom: none !important;
}

td.e-work-cells.e-work-days.e-current-date .e-date-header.e-navigate {
    background: #FFFFFF00;
}

.e-schedule .e-schedule-table>tbody>tr>td {
    white-space: unset !important;
}

.e-appointment.e-schedule-event-clone {
    background: none !important;
    box-shadow: none !important;
}

.e-visits-adjust-calendar {
    height: 750px;
}