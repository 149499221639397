// .auth__white-btn {
//   // background: #ffffff;
//   // border-radius: 32px;
//   // border-style: none;
//   // padding: 20px 16px;
//   // font-weight: 600;
//   // font-size: 18px;
//   // line-height: 26px;
//   // transition: 0.1s;
//   // color: $primary;
//   @media only screen and (max-width: 300px) {
//     font-size: 16px;
//   }
// }
.auth__white-btn {
  background: #ffffff;
  border-radius: 8px;
  border-style: none;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  transition: 0.1s;
  font-family: $font_primary;
  color: $primary;
  width: 389px;
  height: 43px;
  margin-top: 17px;
  border: solid 1.5px $primary;

  a {
    text-decoration: none !important;
    color: $primary;
  }

  @media only screen and (max-width: 300px) {
    font-size: 16px;
  }

  &:disabled{
    background: #F3F4F6;
    color: #9DA4AE;
    border: none !important;
  }
}

// .auth__white-btn:hover,
// .auth__white-btn:active {
//   border: solid 1.5px $primary  !important;
//   background: #ffffff !important;

//   a {
//     color: #3fd4af !important;
//   }
// }

.auth__white-btn_signin {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: #0BB68C;
  margin-top: 19px;

  width: 248px;
  height: 43px;
  background: #FFFFFF;
  border: none;
  border-radius: 8px;

  &:hover {
    background: #FFFFFF;
    color: #6C737F;
  }

  &:active {
    background: #FFFFFF;
    color: #1F2A37;
  }
}

.auth__white-btn_signup {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 389px;
  height: 43px;
  background: #FFFFFF;
  border: 1px solid #4D5761;
  border-radius: 6px;

  margin-top: 7px;

  width: 389px;
  height: 43px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: #384250;

  &:hover {
    background: #FFFFFF;
    color: #6C737F;
  }

  &:active {
    background: #FFFFFF;
    color: #1F2A37;
  }
  
  &:disabled{
    background: #F3F4F6;
    color: #9DA4AE;
    border: none !important;
  }
}

.auth__green-btn {
  background: $primary;
  border-radius: 8px;
  border-style: none;
  color: white;
  font-size: 18px;
  font-weight: 600;
  height: 43px;
  line-height: 26px;
  width: 100%;

  &.recovery {
    margin-top: 74px;
  }

  &:focus,
  &:hover,
  &:active {
    background: #3fd4af !important;
    border: 1px solid #d7fef4;
    box-shadow: none !important;
  }
}

.auth__green-btn-signup {
  background: $primary;
  border-radius: 8px;
  border-style: none;
  color: white;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  height: 43px;
  min-width: 322px;



  &:hover {
    background: #3FD4AF;
    color: #FFFFFF;
  }

  &:active {
    background: #21856C;
    color: #FFFFFF;
  }
  &:disabled{
    background: #F3F4F6;
    color: #9DA4AE;
  }

}

.auth__green-btn-loging {

  border-style: none;
  color: white;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  height: 43px;
  width: 550px;

  background: #0BB68C;
  border-radius: 8px;

  &:hover {
    background: #3FD4AF;
    color: #FFFFFF;
  }

  &:active {
    background: #21856C;
    color: #FFFFFF;
  }
  &:disabled{
    background: #F3F4F6;
    color: #9DA4AE;
    border: none !important;
  }

  
}