.visitDetail__container {
  border: 2px solid rgba(0,0,0, 0.4);
  padding: 8px;
  border-radius: 20px;
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  div {
    font-size: 12px;
  }
}
