.modal-patient-bulk-upload {
  position: fixed;
  background: #ffffff;
  border-radius: 16px;
  width: 50vw;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 40px 48px;
}

.modal-patient-bulk-title {
  height: 22px;

  /* Text Large/Medium */

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  /* Gray neutral/900 */

  color: #111927;
}

.modal-patient-bulk-title-section {
  /* Text Small/Medium */

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Gray neutral/700 */

  color: #384250;
}

.modal-patient-bulk-subtitle {
  /* Text Small/Regular */

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Gray neutral/500 */

  color: #6c737f;
}

.modal-patient-button-close-bulk {
  width: 20px;
  height: 19px;

  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  display: flex;

  /* Gray neutral/700 */

  color: #384250;
}

.second-section-bulk-upload {
  padding: 16px;
  /* Success/25 */

  background: #f6fef9;
  /* Success/400 */

  border: 1px solid #32d583;
  border-radius: 8px;
}

.c-form-bulk-upload {
  height: 126px;
}

.third-section-bulk-upload {
  position: relative;
  width: 100%;
  overflow: hidden;
  /* Success/25 */
  height: auto;
  background: #f6fef9;
  /* Success/400 */

  border: 1px solid #bbfbeb;
  border-radius: 8px;
}

.third-section-bulk-upload:hover {
  border: 1px dashed #0bb68c;
}

.bulk-icon-play {
  width: 24px;
  height: 24px;

  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 24px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;

  /* Success/600 */

  color: #039855;

  /* Inside auto layout */

  order: 0;
  flex-grow: 0;
}

.second-section-bulk-upload {
  padding-left: 40px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Success/600 */

  color: #039855;

  /* Inside auto layout */

  order: 1;
  flex-grow: 0;
}

.second-section-bulk-lightbulb {
  width: 15px;
  height: 20px;
  position: absolute;
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  /* identical to box height */
  top: 29%;
  left: 60px;
  display: flex;
  align-items: center;
  text-align: center;

  /* Brand 2.0/800 */

  color: #067358;

  /* Inside auto layout */

  order: 0;
  flex-grow: 0;
}

.second-section-bulk-watch {
  /* Text sm/Normal */

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Success/600 */

  color: #039855;

  /* Inside auto layout */

  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.second-section-bulk-subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Success/700 */

  color: #027a48;
}

.second-section-bulk-subtitle:hover {
  cursor: pointer;
}

.bulk-click-title-first {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #12634f;
}

.bulk-click-title-first-1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #21856c;
}

.bulk-click-title-second-1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #6c737f;
}

.bulk-click-title-second {
  width: 112px;
  height: 20px;

  /* Text sm/Normal */

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Brand 2.0/500 */

  color: #0bb68c;

  /* Inside auto layout */

  order: 1;
  flex-grow: 0;
}

.bulk-click-title-footer {
  /* Text xs/Normal */

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */

  text-align: center;

  /* Brand 2.0/500 */

  color: #0bb68c;

  /* Inside auto layout */

  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.bulk-file-error {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: red;

  /* identical to box height, or 143% */
}

.file-upload-content {
  display: none;
  text-align: center;
}

.file-upload-input {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  outline: none;
  opacity: 0;
  cursor: pointer;
}

.image-upload-wrap {
  position: relative;
  height: 100%;
  border: 4px solid #d0d7de;
  margin-left: 10px;
  margin-right: 10px;
}

.bulk-patient-form {
  display: grid;
  justify-content: center;
  /* margin: 12%; */
  margin-top: 112px;
  padding: 0;
}

.bulk-patient-form:hover {
  background-color: transparent;
  // border: 1px dashed #0bb68c;
}
.bulk-upload-cloud {
  display: flex;
  justify-content: center;
  margin-top: 16px;
  /* Brand/100 */

  & span {
    background: #ebfffa;
    /* Brand/50 */

    border: 6px solid #f5fffd;
    border-radius: 28px;
  }
}
