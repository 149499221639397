.user-error-container {
  border: 1px solid #f97066;
  border-radius: 8px;
  margin: 1rem 0;
  background-color: #fffbfa;
}
.careteam-error-container {
  border: 1px solid #f97066;
  border-radius: 8px;
  // margin: 1rem 5rem;
  margin: 1rem 0;
  background-color: #fffbfa;
}
.aid-error-container {

  box-sizing: border-box;
  position: absolute;
  left: 202px;
  z-index: 999999999;

/* Auto layout */

display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
padding: 12px 12px 12px 12px;
  width: 349px;
height: 72px;
  background: #FEF3F2;
/* Error/500 */

border: 1px solid #F04438;
/* Shadow/lg */

box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
border-radius: 12px;

& button{
  width: 20px;
height: 19px;

font-family: 'Font Awesome 6 Pro';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 20px;
display: flex;
align-items: center;
text-align: center;

/* Error/500 */

color: #F04438;
}
}
.user-setting-error-normal-text {
  font-family: "Inter";
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #b42318;
}
.user-setting-error-bold-text {
  font-family: "Inter";
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #b42318;
}
.aid-setting-error-bold-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #D92D20;
}
.exclamation-user-error {
  border: 2px solid #b42318;
}

p.form-hint-text-user {
  /* Text Small/Regular */

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Gray neutral/500 */

  color: #6c737f;
}
