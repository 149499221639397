// Variable overrides
$primary: #0bb68c;

$brand-500: #3fd4af;
$brand-700: #0bb68c;
$brand-800: #21856c;

$gray-700: #384250;

$font-gray: #484f5a;
$dark-gray: #3a3e3d;
$gray: #1D2939;

$font_primary: "Inter";

$body-bg: "#f9f9f9";

@mixin devices($breakpoint) {
  @if $breakpoint ==tablet {
    @media only screen and (min-width: 760px) {
      @content;
    }
  }

  @if $breakpoint ==mobile {
    @media only screen and (min-width: 390px) {
      @content;
    }
  }
}