.modal-patient-success-error {
  position: fixed;
  background-color: #fffbfa;
  width: 365px;
  height: 242px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  z-index: 2000;
  &.error {
    border: 1px solid #f04438;
  }
  &.success {
    border: 1px solid #12b76a;
  }
}
.modal-patient-title {
  width: 273px;
  height: 29px;

  /* Display Extra Small/Semibold */

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */

  /* Error/700 */
}
.modal-patient-title-error {
  color: #b42318;
}
.modal-patient-title-success {
  color: #027a48;
}
.modal-patient-button-close-success-error {
  width: 20px;
  height: 19px;

  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;

  /* Gray neutral/700 */

  color: #384250;
}

.modal-patient-body-title {
  height: 22px;

  /* Text Large/Medium */

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
}

.modal-patient-body-title-error {
  color: #b42318;
}
.modal-patient-body-title-success {
  color: #027a48;
}
.modal-patient-bodyDetail-title {
  height: 60px;

  /* Text sm/Normal */

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.modal-patient-bodyDetail-title-error {
  color: #d92d20;
}
.modal-patient-bodyDetail-title-success {
  color: #027a48;
}

.modal-patient-footer-title {
  width: 87px;
  height: 20px;

  /* Text sm/Medium */

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
}
.modal-patient-footer-title-success {
  color: #027a48;
}
.modal-patient-footer-title-error {
  color: #b42318;
}

.modal-patient-arrow {
  width: 16px;
  height: 16px;

  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 3px;
}
.modal-patient-arrow-success {
  color: #027a48;
}

.modal-patient-arrow-error {
  color: #b42318;
}

@keyframes dotSpin {
  0%,
  100% {
    box-shadow: 0 -18px 0 0 #027a48, 12.72984px -12.72984px 0 0 #027a48,
      18px 0 0 0 #027a48, 12.72984px 12.72984px 0 -5px rgba(2, 122, 72, 0),
      0 18px 0 -5px rgba(2, 122, 72, 0),
      -12.72984px 12.72984px 0 -5px rgba(2, 122, 72, 0),
      -18px 0 0 -5px rgba(2, 122, 72, 0),
      -12.72984px -12.72984px 0 -5px rgba(2, 122, 72, 0);
  }
  12.5% {
    box-shadow: 0 -18px 0 -5px rgba(2, 122, 72, 0),
      12.72984px -12.72984px 0 0 #027a48, 18px 0 0 0 #027a48,
      12.72984px 12.72984px 0 0 #027a48, 0 18px 0 -5px rgba(2, 122, 72, 0),
      -12.72984px 12.72984px 0 -5px rgba(2, 122, 72, 0),
      -18px 0 0 -5px rgba(2, 122, 72, 0),
      -12.72984px -12.72984px 0 -5px rgba(2, 122, 72, 0);
  }
  25% {
    box-shadow: 0 -18px 0 -5px rgba(2, 122, 72, 0),
      12.72984px -12.72984px 0 -5px rgba(2, 122, 72, 0), 18px 0 0 0 #027a48,
      12.72984px 12.72984px 0 0 #027a48, 0 18px 0 0 #027a48,
      -12.72984px 12.72984px 0 -5px rgba(2, 122, 72, 0),
      -18px 0 0 -5px rgba(2, 122, 72, 0),
      -12.72984px -12.72984px 0 -5px rgba(2, 122, 72, 0);
  }
  37.5% {
    box-shadow: 0 -18px 0 -5px rgba(2, 122, 72, 0),
      12.72984px -12.72984px 0 -5px rgba(2, 122, 72, 0),
      18px 0 0 -5px rgba(2, 122, 72, 0), 12.72984px 12.72984px 0 0 #027a48,
      0 18px 0 0 #027a48, -12.72984px 12.72984px 0 0 #027a48,
      -18px 0 0 -5px rgba(2, 122, 72, 0),
      -12.72984px -12.72984px 0 -5px rgba(2, 122, 72, 0);
  }
  45% {
    box-shadow: 0 -18px 0 -5px rgba(2, 122, 72, 0),
      12.72984px -12.72984px 0 -5px rgba(2, 122, 72, 0),
      18px 0 0 -5px rgba(2, 122, 72, 0),
      12.72984px 12.72984px 0 -5px rgba(2, 122, 72, 0), 0 18px 0 0 #027a48,
      -12.72984px 12.72984px 0 0 #027a48, -18px 0 0 0 #027a48,
      -12.72984px -12.72984px 0 -5px rgba(2, 122, 72, 0);
  }
  50% {
    box-shadow: 0 -18px 0 -5px rgba(2, 122, 72, 0),
      12.72984px -12.72984px 0 -5px rgba(2, 122, 72, 0),
      18px 0 0 -5px rgba(2, 122, 72, 0),
      12.72984px 12.72984px 0 -5px rgba(2, 122, 72, 0),
      0 18px 0 -5px rgba(2, 122, 72, 0), -12.72984px 12.72984px 0 0 #027a48,
      -18px 0 0 0 #027a48, -12.72984px -12.72984px 0 0 #027a48;
  }
  65% {
    box-shadow: 0 -18px 0 0 #027a48,
      12.72984px -12.72984px 0 -5px rgba(2, 122, 72, 0),
      18px 0 0 -5px rgba(2, 122, 72, 0),
      12.72984px 12.72984px 0 -5px rgba(2, 122, 72, 0),
      0 18px 0 -5px rgba(2, 122, 72, 0),
      -12.72984px 12.72984px 0 -5px rgba(2, 122, 72, 0), -18px 0 0 0 #027a48,
      -12.72984px -12.72984px 0 0 #027a48;
  }
  75.5% {
    box-shadow: 0 -18px 0 0 #027a48, 12.72984px -12.72984px 0 0 #027a48,
      18px 0 0 -5px rgba(2, 122, 72, 0),
      12.72984px 12.72984px 0 -5px rgba(2, 122, 72, 0),
      0 18px 0 -5px rgba(2, 122, 72, 0),
      -12.72984px 12.72984px 0 -5px rgba(2, 122, 72, 0),
      -18px 0 0 -5px rgba(2, 122, 72, 0), -12.72984px -12.72984px 0 0 #027a48;
  }
  87.5% {
    box-shadow: 0 -18px 0 0 #027a48, 12.72984px -12.72984px 0 0 #027a48,
      18px 0 0 -5px rgba(2, 122, 72, 0),
      12.72984px 12.72984px 0 -5px rgba(2, 122, 72, 0),
      0 18px 0 -5px rgba(2, 122, 72, 0),
      -12.72984px 12.72984px 0 -5px rgba(2, 122, 72, 0),
      -18px 0 0 -5px rgba(2, 122, 72, 0), -12.72984px -12.72984px 0 0 #027a48;
  }
}
.loading-spinner {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: transparent;
  color: transparent;
  box-shadow: 0 -18px 0 0 #027a48, 12.72984px -12.72984px 0 0 #027a48,
    18px 0 0 0 #027a48, 12.72984px 12.72984px 0 0 rgba(2, 122, 72, 0),
    0 18px 0 0 rgba(2, 122, 72, 0),
    -12.72984px 12.72984px 0 0 rgba(2, 122, 72, 0),
    -18px 0 0 0 rgba(2, 122, 72, 0),
    -12.72984px -12.72984px 0 0 rgba(2, 122, 72, 0);
  animation: dotSpin 1.5s infinite linear;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 150px;
}

.loading-title {
  display: flex;
  justify-content: center;
  // width: 159px;
  // height: 19px;

  // /* Text Base/Regular */

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;

  // text-align: center;

  // /* Gray/700 */

  color: #344054;
}
