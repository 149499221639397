.certification__title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-transform: capitalize;
  color: #0BB68C;
}
.freq-legend-label{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #384250;
    }
.freq-legend{
  width: 10px;
  min-height: 10px;
  max-height: 10px;
  border-radius: 3px;

  &.admission{
  background: #8CD5FF;
  }
  &.dre{
    background: #439CBA;
    }
 &.recert {
      background: #EC6B66;
 }
  &.discharge {
        background: #FFD15E;
  }

  
}

.certification__container {
  width: 567px;
  height: 394px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 3.2px 7.2px rgba(0, 0, 0, 0.092),
  0px 0.6px 1.8px rgba(0, 0, 0, 0.068);
  display: flex;
  flex-direction: column;
  padding: 28px;
}

.certification__name {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #1F2A37;
}

.cert-title-row {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 20px;
  // align-items: center;
  color: #1F2A37;
}

.cert__row-data {
  color: #484f5a;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  transition: all 0.1s;

  .info {
    padding-left: 20px;
  }


  &:hover {
    background: #bdf0e4;
    color: #1F2A37;
    cursor: pointer;

    .info {
      padding-left: 12px;
    }

    // transform: translate(-8px);
  }
  &:active{
    background: #21856c;
    color: #ffffff;
  }
}

.cert-continer {
  height: 154px;
  overflow-y: auto;
}

.cert__btn {
  padding: 8px 16px;
  gap: 10px;

  min-width: 135px;
  min-height: 31px;
}

.cert__btn__primary {
  padding: 8px 16px;
  gap: 10px;
  min-width: 135px;
  min-height: 31px;

  &:hover {
    background: #3FD4AF
  }

  &:active {
    background: #21856C
  }
}

.cert__btn__outline {
  padding: 8px 16px;
  gap: 10px;
  min-width: 135px;
  min-height: 31px;

  &:hover {
    color: #6C737F
  }

  &:active {
    color: #1F2A37
  }
}

.cert__datepicker {
  color: #484f5a;
  font-size: 14px;
  line-height: 21px;
  width: 100%;
}

.dialog-sheet {
  background-color: white;
  width: 310px;
  height: 351px;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  border-radius: 12px;

  .rdp {
    padding: 20px 12px 12px;
    margin: 0px;
    width: 100%;
    height: 100%;

    .rdp-months {
      height: 100%;
      width: 100%;

      .rdp-month {
        height: 100%;
        width: 100%;

        .rdp-caption {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 2px 4px;
          gap: 57px;
          height: 28px;

          .rdp-button:hover {
            background: #ffffff;
            filter: none;
          }

          .rdp-button:active {
            background: #ffffff;
            border-color: #ffffff;
            filter: none;
          }

          .rdp-button:focus {
            background: #ffffff;
            border-color: #ffffff;
            filter: none;
          }

          .rdp-caption_label {
            position: absolute;
            text-transform: capitalize;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 200px;
            left: 40px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #202939;
          }

          .rdp-nav {
            position: absolute;
            left: 12px;
          }

          .rdp-nav_button_next {
            position: absolute !important;
            bottom: -20px;
            left: 250px;
          }

          .rdp-nav_button_previous {
            position: absolute !important;
            bottom: -20px;
            left: 0px;
          }
        }

        .rdp-table {
          width: 100%;
          max-width: 100%;
          height: calc(100% - 28px);
          margin: 0px;

          font-family: 'Inter';
          font-style: normal;

          .rdp-head {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #202939;
            height: 40px;
            text-transform: capitalize;

            .rdp-head_cell {
              text-transform: capitalize;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
            }
          }

          .rdp-tbody {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            color: #364152;
            width: 286px;

            .rdp-row {
              height: 40px;
              max-height: 40px;
            }

            .rdp-button:hover {
              background: #aee9da;
              filter: none;
            }

            .rdp-button:active {
              background: rgb(104, 207, 182);
              border-color: rgb(104, 207, 182);
              filter: none;
            }

            .rdp-button:focus {
              background: #ffffff;
              border-color: #ffffff;
              filter: none;
            }

            .rdp-day_selected {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: 39px;
              height: 39px;
              padding: 10px 15px;
              background: #0BB68C !important;
              border-radius: 20px;
            }
          }
        }
      }
    }
  }
}

.cert-date-holder {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;

  .date-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #384250;
    text-align: center;
    text-transform: capitalize;
    padding-top: 2px;
  }

  height: 44px;
  background: #FFFFFF;
  border: 1px solid #D2D6DB;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.cert__text {
  margin-top: 2px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #384250;
}

.cert__text_no {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #384250;

  b {
    font-weight: 600;
    color: #0bb68c;
  }
}

.cert__text_title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #384250;
}
.frecuency__field_container{
  position: absolute;
  z-index: 1000;
  width: 266px;
  background: white;
  margin-bottom: 26px;
  height: 62px;
}
.frecuency__field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.01em;
  background: #0bb68c;
  border-radius: 4px;
  color: #ffffff;
  height: 31px;
  min-width: 45px;
  padding: 5px 12px;
  text-align: center;

  &.add{
    max-width: 45px;
  background: transparent;
  color: #1D2939;
  // border: 1px dashed #475467;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='black' stroke-width='3' stroke-dasharray='4%2c8' stroke-dashoffset='56' stroke-linecap='square'/%3e%3c/svg%3e");
  // border-radius: 4px;

  & svg{
    position: absolute;
    left: 12px;
    top: -33px;
    }
  }
  &.edit{
    cursor: pointer;
  }
  &.disabled{
    cursor: default;
    background: #F3F4F6;
    color: #9DA4AE;
  }
}

.cert__delete-tooltip {
  background: #f5f9fa;
  border-radius: 4px;
  box-shadow: 0px 3.2px 7.2px rgba(0, 0, 0, 0.132),
  0px 0.6px 1.8px rgba(0, 0, 0, 0.108);
  min-height: 94px;
  min-width: 163px;

  h3 {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */

    display: flex;
    align-items: center;

    /* Gray */

    color: #484f5a;
  }

  button {
    min-height: 28px;
    min-width: 56px;
    padding: 5px 8px;
  }
}

.cert__paragraph {
  font-weight: 300;
  font-size: 10px;
  line-height: 26px;
  color: #7c7c8e;
}

.step__container {
  font-family: 'Inter';
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
  color: #1F2A37;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 3.2px 7.2px rgba(0, 0, 0, 0.132),
  0px 0.6px 1.8px rgba(0, 0, 0, 0.108);
  // min-height: calc(100% - 19px);
  min-height: 229px;
  width: 100%;
  padding: 17px 23px 14px 24px;

  &.step2__box {
    padding: 16px 16px 17px;
  }

  .frecs__container {
    overflow-y: auto;
    margin-top: 16px !important;
    margin-bottom: 0px !important;
    width: 100%;
    padding-right: 5px;
  }

  .frecs-header-title {
    font-weight: 600;

  }

  .frecs-texts {
    font-weight: 400;
    text-align: center;
  }
}

.in_edit_freq {
  border: solid 1px $primary !important;
  // box-shadow: 0px 3.2px 7.2px #0BB68C80,
  //   0px 0.6px 1.8px #0BB68C80 !important;
}

.radio__btn {
  appearance: none;
  cursor: pointer;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  outline: 2px solid #D2D6DB;
  outline-offset: 5px;

  &:focus {
    outline: 2px solid $primary !important;
    outline-offset: 5px !important;
  }

  &:focus-visible {
    outline: 2px solid $primary;
    outline-offset: 5px;
  }

  &:checked {
    background: $primary;
    outline: 2px solid $primary;
    outline-offset: 5px;
  }
}

.radio__label {
  font-family: 'Inter';
  font-style: normal;

  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #D2D6DB;

  b {

    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: $primary;
  }
}

.frequency_selector {
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 0.6px 1.8px rgba(0, 0, 0, 0.1),
  0px 3.2px 7.2px rgba(0, 0, 0, 0.13);
  color: #484f5a;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  padding: 0 3px;
  place-content: space-around;
  text-align: center;
  width: 22%;
  max-height: 32px;
  height: calc(100% - 2px);
}

.cancel__freq_btn{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 67px;
  height: 31px;
  background: #ffffff;
  border-radius: 4px;
  transition: all 0.05s;
  border: 1px solid #4d5761;

    //styleName: Text Extra Small/Medium;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #384250;
  
    &:hover {
      border: 1px solid #6c737f;
      color: #6c737f;
    }
  
    &:active {
      order: 1px solid #1f2a37;
      color: #1f2a37;
    }
}
.add__btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 67px;
  height: 31px;
  background: #0BB68C;
  border-radius: 4px;
  color: #ffffff;
  transition: all 0.05s;
  &:hover {
    background: #3FD4AF
  }

  &:active {
    color: $brand-800;
    background: #21856C;

    & > span.plus {
      border-color: $brand-800;
    }
  }

  & > span.plus {
    border: 2px solid $gray-700;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    width: 2rem;
  }
}

.calendar-frecs {
  width: inherit;
  font-family: 'Inter';
  font-style: normal;
  height: inherit;
  color: #1F2A37;

  .month-label {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
  }

  .days {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    align-items: center;
    text-align: center;
  }

  .outside-month {
    color: #9DA4AE;
  }

  .frecs-range {
    background: #D1E9FF;
    margin-bottom: 1px;
    margin-top: 1px;

    &.recert {
      // background: #FDA29B;
    }

    &.initial {
      border-top-left-radius: 7px;
      border-bottom-left-radius: 7px;
    }

    &.final {
      border-top-right-radius: 7px;
      border-bottom-right-radius: 7px;
    }
  }

  .frecs-addmission {
    // background: #8CD5FF;
    width: 100%;
    height: 100%;
    max-width: 40px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .frecs-30 {
    // background: #439CBA;
    color: #ffffff;
    width: 100%;
    height: 100%;
    max-width: 40px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .ini-recert {
    // background: #FDA29B;
    width: 100%;
    height: 100%;
    max-width: 40px;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    &.force-end {
      border-top-right-radius: 7px;
      border-bottom-right-radius: 7px;
    }
    &.end-recert-range{
      border-top-right-radius: 7px;
      border-bottom-right-radius: 7px;
    }
  }
}

.new-cert-form {
  width: 507px;
  height: 518px;
}

.edit-cert-form {
  // max-width: 1200px;
  overflow: hidden !important;
  min-width: 60vw;
  width: auto;
  min-height: 1012px;
  max-height: 1057px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  .step-title {
    text-align: start;
    color: #393939;
  }

  .step1-tag {
    line-height: 19px;
    text-align: start;
    color: #1F2A37;
  }
}

.delete-cert-form {
  width: 365px;
  height: 309px;
  padding: 32px 24px;
  margin: 0px;
  position: absolute;
  top: calc(calc(100% - 309px) / 2);
}

.delete-cert {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  font-family: 'Inter';
  font-style: normal;
  text-align: center;

  .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #1F2A37;
  }

  .text {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #384250;
  }
}

.frecs-title-calendar {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #344054;
}

.display-frecs {
  padding-top: 4px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  height: 36px;
  color: #202939;
}

.cert-week-count {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #1F2A37;

  .counter {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #0BB68C;
  }
}

@media(max-height: 745px) {
  .popup-content {
    height: 100% !important;
  }
}

@media(max-height: 1030px) {
  .certification__container.edit-cert-form {
    height: 100% !important;
    overflow-y: scroll !important;
    min-height: 0;
  }
}

button.freq-Save-btn {
  height: 35px;
  width: 125px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0BB68C;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #FFF
}

button.freq-save-btn {
  height: 35px;
  width: 125px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0BB68C;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #FFF
}

button.freq-cancel-btn {
  height: 35px;
  width: 125px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFF;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #384250;
  border: solid 1px #384250;
  margin-right: 16px;
}

.opacity-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #00000033;
  z-index: 10;
}

.custom_hover:hover{
  background: #3fd4af !important;
}

.calendar-td {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  }
