.patients-height{
  height: calc(100vh - 226px);
}

.patients__table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  height: calc(100vh - 338px) !important;


  thead {
    align-items: center;

    tr {
      cursor: pointer;
      height: 36px;
      border-radius: 0px !important;
      align-items: center;
      padding: 8px 16px;
      box-sizing: border-box;
      background: #f9fafb;

      .sorted {
        background: #e5e7eb;
      }

      th {
        div {
          width: 100%;
          align-items: center;
          box-sizing: border-box;
          margin-left: 0px;
          padding-left: 16px !important;
        }

        h5 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          text-align: center;
          margin-bottom: 0;
          letter-spacing: 0.01em;
          color: #6c737f;
        }
      }
    }
  }

  tbody {

    td {
      //styleName: Text Small/Regular;
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: left;

      /* identical to box height */

      // display: flex;F

      /* Dark Gray */

      color: #3a3e3d;

      /* Inside auto layout */

      // flex: none;
      order: 0;
      align-self: stretch;
      padding: 15px 16px;
      // flex-grow: 0;
    }

    td.table__data.status p {
      margin-bottom: 0 !important;
      width: fit-content;
      padding: 3px 14px;
      border-radius: 16px;
    }

    td.table__data.status.active p {
      background: #ecfdf3;
      color: #027a48;
    }

    td.table__data.status.hospital p {
      background: #f5f3ff;
      color: #6927da;
    }

    td.table__data.status.onhold p {
      background: #eaecf0;
      color: #1d2939;
    }

    td.table__data.status.pending p {
      background: #eaecf0;
      color: #1d2939;
    }

    td.table__data.status.discharged p {
      background: #fefbe8;
      color: #a15c07;
    }

    td.table__data.status.inactive p {
      background: #fefbe8;
      color: #a15c07;
    }
  }

  .table__row {
    &:hover {
      background: #e5e7eb !important;
    }
  }
}

.table__without__patient {
  height: 50vh;
  position: relative;

  margin-left: 10px;
  margin-right: 10px;
}

.users__table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;

  thead {
    align-items: center;

    tr {
      cursor: pointer;
      height: 36px;
      border-radius: 0px !important;
      align-items: center;
      padding: 8px 16px;
      box-sizing: border-box;
      background: #f9fafb;

      .sorted {
        background: #e5e7eb;
      }

      th {
        div {
          width: 100%;
          align-items: center;
          box-sizing: border-box;
          margin-left: 0px;
          padding-left: 16px !important;
        }

        h5 {
          // font-family: "Inter";
          // font-style: normal;
          // font-weight: 500;
          // font-size: 12px;
          // line-height: 15px;
          // text-align: center;
          // margin-bottom: 0;
          // letter-spacing: 0.01em;
          // color: #6c737f;

          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          /* identical to box height */

          display: flex;
          align-items: center;

          /* Gray neutral/900 */

          color: #111927;
        }
      }
    }
  }

  tbody {

    td {
      /* Text Small/Regular */

      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height */

      align-items: center;

      /* Gray neutral/800 */

      color: #1f2a37;

      /* Inside auto layout */

      // flex: none;
      order: 0;
      align-self: stretch;
      padding: 15px 16px;
      // flex-grow: 0;
    }

    td.table__data.status p {
      margin-bottom: 0 !important;
      width: fit-content;
      padding: 3px 14px;
      border-radius: 16px;
    }

    td.table__data.status.active p {
      background: #ecfdf3;
      color: #027a48;
    }

    td.table__data.status.hospital p {
      background: #f5f3ff;
      color: #6927da;
    }

    td.table__data.status.on.hold p {
      background: #eaecf0;
      color: #1d2939;
    }

    td.table__data.status.discharged p {
      background: #fefbe8;
      color: #a15c07;
    }

    td.table__data.status.inactive p {
      background: #fefbe8;
      color: #a15c07;
    }
  }

  .table__row {
    &:hover {
      background: #e5e7eb !important;
    }
  }
}
