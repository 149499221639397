.user__green-container {
  background: #0bb68c;
  display: flex;
  flex-direction: column;
  grid-column: span 5;
  height: 248px;
  place-content: center;
  align-items: flex-start;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.user-edit-tab-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 10px;

  width: auto;
  height: 31px;
  left: 1221px;
  top: 384px;

  /* Base/White */

  background: #ffffff;
  /* Gray neutral/600 */

  border: 1px solid #4d5761;
  border-radius: 4px;

  /* Text Extra Small/Medium */

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.01em;

  /* Gray neutral/700 */

  color: #384250;
  &:hover {
    border: 1px solid #6c737f;
    color: #6c737f;
  }
  &:active {
    border: 1px solid #1f2a37;
    color: #1f2a37;
  }
}

.user-delete-tab-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 10px;

  width: auto;
  height: 31px;
  left: 1221px;
  top: 384px;

  /* Base/White */

  background: #ffffff;
  /* Error/700 */

  border: 1px solid #b42318;
  border-radius: 4px;

  /* Text Extra Small/Medium */

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.01em;

  /* Gray neutral/700 */

  color: #b42318;
  &:hover {
    /* Base/White */

    background: #ffffff;
    /* Error/500 */
    color: #f04438;
    border: 1px solid #f04438;
    border-radius: 4px;
  }
  &:active {
    background: #ffffff;
    /* Error/800 */
    color: #912018;
    border: 1px solid #912018;
    border-radius: 4px;
  }
}
