.picker-container {
    z-index: 200;
  
    width: 50px;
  
    &, *, *:before, *:after {
      box-sizing: border-box;;
    }
  
    .picker-inner {
      position: relative;
  
      display: flex;
      justify-content: center;
      height: 100%;
      padding: 0 20px;
      font-size: 1.2em;
      -webkit-mask-box-image: linear-gradient(to top, transparent, transparent 5%, white 20%, white 80%, transparent 95%, transparent);
    }
  
    .picker-column {
      flex: 1 1;
  
      position: relative;
  
      max-height: 70%;
      min-width: 80px;
      
      overflow: hidden;
      text-align: center;
  
      .picker-scroller {
        transition: 300ms;
        transition-timing-function: ease-out;
      }
  
      .picker-item {
        position: relative;
        cursor: pointer;       
        padding: 0 10px;
  
        white-space: nowrap;
        color: #999999;
        overflow: hidden;
        text-overflow: ellipsis;
  
        &.picker-item-selected {
          cursor: pointer;
          color: #222;
          &:after {
            content: ' ';
    position: absolute;
    top: 100%;
    left: 50%;
    width: 33%;
    height: 3px;
    margin-right: 50%;
    background-color: #0bb68c;
    transform: translate(-50%,-50%);
            // content: ' ';
            // position: absolute;
            
            // left: -10px;
            // right: auto;
      
            // display: block;
            // width: 100%;
            // height: 4px;
      
            // background-color: #0bb68c;
      
            // transform: scaleY(0.5);
          }     
      
          &:after {
            bottom: 0;
            top: auto;
          }
        }
      }
    }
    .picker-column:nth-child(2) {
      flex: 1 1;
  
      position: relative;
  
      max-height: 70%;
      min-width: 80px;
      
      overflow: hidden;
      text-align: center;
  
      .picker-scroller {
        transition: 300ms;
        transition-timing-function: ease-out;
      }
  
      .picker-item {
        position: relative;
        cursor: pointer;       
        padding: 0 10px;
  
        white-space: nowrap;
        color: #999999;
        overflow: hidden;
        text-overflow: ellipsis;
  
        &.picker-item-selected {
          cursor: pointer;
          color: #222;
          &:after {
    //         content: ' ';
    // position: absolute;
    // top: 100%;
    // left: 50%;
    // width: 53%;
    // height: 4px;
    // margin-right: 50%;
    // background-color: #0bb68c;
    // transform: translate(-50%,-50%);
            content: ' ';
            position: absolute;
            
            // left: -10px;
            right: auto;
      
            display: block;
            width: 100%;
            height: 3px;
      
            background-color: #0bb68c;
      
            transform: translate(-50%,-50%);
          }     
      
          &:after {
            bottom: 0;
            top: auto;
          }
        }
      }
    }

  
    .picker-highlight {
      display: none !important;
      position: absolute;
      top: 50%;
      left: 0;
  
      width: 100%;
  
      pointer-events: none;
  
      &:before, &:after {
        content: ' ';
        position: absolute;
        
        left: 0;
        right: auto;
  
        display: block;
        width: 100%;
        height: 1px;
  
        background-color: #0bb68c;
  
        transform: scaleY(0.5);
      }
  
      &:before {
        top: 0;
        bottom: auto;
      }
  
      &:after {
        bottom: 0;
        top: auto;
      }
    }
  }