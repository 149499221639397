.hr-button {
  display: flex;
}

.hr-button-line {
  width: 100%;
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 21, 0.2);
}

.hr-button {
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
  top: 6px;
}

//forms tab
.add-another-contact:focus,
span.e-input-group:focus,
.e-input-focus,
.selected-flag:focus,
.patient-form input.text-input:focus, 
.patient-form .list__box_dropdown_user button:focus, 
input.form-control.custom-phone-input:focus,
.checkbox__input.parking input:focus,
.list__box_dropdown_patient button:focus,
.list__box_dropdown_careteam button:focus,
.text__area textarea:focus,
.cancel_btn:focus,
.save_btn:focus,
.dropdown-error-class.custom-focus {
  border-width: 2px !important;
}
.save_btn:focus{
  opacity: 0.8;
}

.flag-dropdown .selected-flag {
  border-radius: 8px 0 0 8px !important;
  background: transparent !important;
  border-color: inherit;
}