.autoschedule-circle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px;
    gap: 4px;
    width: 48px;
    height: 48px;
    //Disable for now FFFFFF
    background: #F3F4F6;;
    //box-shadow: 0px 3.2px 7.2px rgba(0, 0, 0, 0.132), 0px 0.6px 1.8px rgba(0, 0, 0, 0.108);
    border-radius: 100px;
    // cursor: pointer;
    cursor: default;
}

.autoschedule-modal {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 16px 16px;
    gap: 8px;
    position: absolute;
    width: 148px;
    height: 87px;
    left: calc(100% - 10px);
    bottom: 60px;
    z-index: 10;
    background: #FCFCFD;
    border: 1px solid #4D5761;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    border-radius: 8px;

    .modal-title {
        flex-direction: row;
        // justify-content: center;
        align-items: center;
        padding: 0px 8px 4px;
        width: 116px;
        height: 24px;
        border-bottom: 1px solid #D0D5DD;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #384250;
    }

    .modal-option {
        justify-content: center;
        align-items: center;
        padding: 8px;
        width: 116px;
        height: 31px;
        background: #FFFFFF;
        border: 1px solid #4D5761;
        border-radius: 4px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        letter-spacing: 0.01em;
        color: #384250;

        &:hover{
            background: #EBFFFA;
            border: 1px solid #0BB68C;
        }
        &:active{
            background: #BBFBEB;
            border: 1px solid #0BB68C;
        }
    }

    .modal-option-loading {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px;
        width: 116px;
        height: 31px;
    }
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}