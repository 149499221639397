.user__avatar_container {
  margin-top: 105px;
  margin-left: 20px;
  z-index: 100;

  & img#picture.avatar__image {
    border: 3.31px solid #72c6a9;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
      0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    border-radius: 500px;
    height: 200px;
    width: 200px;
  }
}
.user__avatar_container_second {
  z-index: 100;

  & img#picture2.avatar__image_second {
    border: 4px solid #e5e7eb;
    border-radius: 200px;

    width: 64px;
    height: 64px;
  }
}
.dropdown-header-avatar {
  margin-left: 13px !important;

  .btn {
    img {
      border-radius: 10px !important;
    }
    &:focus,
    &:focus-visible {
      outline: none;
    }
  }
  .dropdown-item-user {
    padding-top: 7px !important;
    padding-left: 0 !important;
  }
  .dropdown-item.active,
  .dropdown-item:active {
    background-color: transparent;
  }
  .dropdown-menu {
    box-shadow: none;

    padding: 0;
  }
  .dropdown-menu.show {
    border-radius: 8px;

    .dropdown-item:nth-child(1):hover {
      background-color: lightcyan;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }
    .dropdown-item:nth-child(2):hover {
      background-color: lightcyan;
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  }
  .dropdown-user-header {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    text-decoration: none;
    display: flex;
    justify-content: start;
    align-items: center;
    color: #797979;
    padding-top: 3px;
    padding-bottom: 3px;
    margin-left: 18px;
    width: 100%;
    &:hover {
      color: #027a48 !important;
    }
  }
}

.custom-upload-avatar {
  height: 30px;
  position: relative;
  margin: 0 40px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;

  & #avatar-file-input.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 27px;
    margin: 0;
    opacity: 0;
  }
}
